import { useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { request } from "../features/featchApi";

const GetInTouch = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const sendDataToBackend = async (data) => {
    try {
      const headers= {
        'Content-Type' : "application/json"
      }
      setFormData({
        name: "",
        email: "",
        message: "",
      });
      
      const dataa = await request("/Joinusrequest", "POST", headers,data)
    } catch (error) {
        console.error(error)
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

  };

  const handleSendMsg = (section) => {
    // Assuming each section has a unique identifier (e.g., "developer", "areaFounder", etc.)
    // You can customize this based on your backend requirements
    sendDataToBackend({
      section,
      ...formData,
    });
  };

  return (
    <div className="mt-32">

      <div className="m-8 rounded bg-secondary p-6 ">
        <h1 className=" text-primary">Why you should join us as Developer ? Read Blog</h1>
        <h1 className="mt-3 text-2xl font-semibold text-slate-200">
          Join us as Developer
        </h1>
        <div className=" px-2 py-1  mt-3">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="sm:w-1/3 w-full block mt-3 bg-transparent border-b outline-none text-slate-200 text-xl p-2"
            style={{ fontSize: '1.5rem', height: '2.5rem' }}
            placeholder="Your Name..."
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="sm:w-1/3 w-full  block mt-3  bg-transparent border-b outline-none text-slate-200 text-xl p-2"
            style={{ fontSize: '1.5rem', height: '2.5rem' }}
            placeholder="Email..."
          />
          <input
            type="textarea"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            className="sm:w-4/5 w-full mt-3   bg-transparent border-b outline-none text-slate-200 text-xl p-2"
            style={{ fontSize: '1.5rem', height: '2rem' }}
            placeholder="Message..."
          />

          <button
            onClick={() => handleSendMsg("developer")}
            className=" mx-auto mt-3 flex px-5 py-2 rounded-md btn-primary"
          >
            <div className="mr-2  ">Send Msg</div>
            <div className="p-1"><FiArrowRight />  </div>
          </button>
        </div>

      </div>

      <div className="m-8 rounded bg-secondary p-6 ">
        <h1 className=" text-primary">Why should of become Nypata area founder? Read blog</h1>
        <h1 className="mt-3 text-2xl font-semibold text-slate-200">
          Join us as Area Founder of Nyapata 
        </h1>
        <div className=" px-2 py-1  mt-3">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="sm:w-1/3 w-full block mt-3 bg-transparent border-b outline-none text-slate-200 text-xl p-2"
            style={{ fontSize: '1.5rem', height: '2.5rem' }}
            placeholder="Your Name..."
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="sm:w-1/3 w-full block mt-3  bg-transparent border-b outline-none text-slate-200 text-xl p-2"
            style={{ fontSize: '1.5rem', height: '2.5rem' }}
            placeholder="Email..."
          />
          <input
            type="textarea"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            className="sm:w-4/5 w-full mt-3   bg-transparent border-b outline-none text-slate-200 text-xl p-2"
            style={{ fontSize: '1.5rem', height: '2rem' }}
            placeholder="Message..."
          />

          <button
            onClick={() => handleSendMsg("areaFounder")}
            className=" mx-auto mt-3 flex px-5 py-2 rounded-md btn-primary"
          >
            <div className="mr-2  ">Send Msg</div>
            <div className="p-1"><FiArrowRight />  </div>
          </button>
        </div>
      </div>

      <div className="m-8 rounded bg-secondary p-6 ">
        <h1 className=" text-primary">Why Should You join as Broker? Read Blog</h1>
        <h1 className="mt-3 text-2xl font-semibold text-slate-200">
          Join us as Broker
        </h1>
        <div className=" px-2 py-1  mt-3">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="sm:w-1/3 w-full block mt-3 bg-transparent border-b outline-none text-slate-200 text-xl p-2"
            style={{ fontSize: '1.5rem', height: '2.5rem' }}
            placeholder="Your Name..."
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="sm:w-1/3 w-full block mt-3  bg-transparent border-b outline-none text-slate-200 text-xl p-2"
            style={{ fontSize: '1.5rem', height: '2.5rem' }}
            placeholder="Email..."
          />
          <input
            type="textarea"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            className="sm:w-4/5 w-full mt-3   bg-transparent border-b outline-none text-slate-200 text-xl p-2"
            style={{ fontSize: '1.5rem', height: '2rem' }}
            placeholder="Message..."
          />

          <button
            onClick={() => handleSendMsg("broker")}
            className=" mx-auto mt-3 flex px-5 py-2 rounded-md btn-primary"
          >
            <div className="mr-2  ">Send Msg</div>
            <div className="p-1"><FiArrowRight />  </div>
          </button>
        </div>
      </div>

      <div className="m-8 rounded bg-secondary p-6 ">
        <h1 className=" text-primary">Why should you advertise here? Read Blog</h1>
        <h1 className="mt-3 text-2xl font-semibold text-slate-200">
          Join us for Advertisment
        </h1>
        <div className=" px-2 py-1  mt-3">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="sm:w-1/3 w-full block mt-3 bg-transparent border-b outline-none text-slate-200 text-xl p-2"
            style={{ fontSize: '1.5rem', height: '2.5rem' }}
            placeholder="Your Name..."
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="sm:w-1/3 w-full block mt-3  bg-transparent border-b outline-none text-slate-200 text-xl p-2"
            style={{ fontSize: '1.5rem', height: '2.5rem' }}
            placeholder="Email..."
          />
          <input
            type="textarea"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            className="sm:w-4/5 w-full mt-3   bg-transparent border-b outline-none text-slate-200 text-xl p-2"
            style={{ fontSize: '1.5rem', height: '2rem' }}
            placeholder="Message..."
          />

          <button
            onClick={() => handleSendMsg("advertisement")}
            className=" mx-auto mt-3 flex px-5 py-2 rounded-md btn-primary"
          >
            <div className="mr-2  ">Send Msg</div>
            <div className="p-1"><FiArrowRight />  </div>
          </button>
        </div>
      </div>

      <div className="m-8 rounded bg-secondary p-6 ">
        <h1 className="mt-3 text-2xl font-semibold text-slate-200">
          You Tell us, How you can contribute to Nyapata?
        </h1>
        <div className=" px-2 py-1  mt-3">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="sm:w-1/3 w-full block mt-3 bg-transparent border-b outline-none text-slate-200 text-xl p-2"
            style={{ fontSize: '1.5rem', height: '2.5rem' }}
            placeholder="Your Name..."
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="sm:w-1/3 w-full block mt-3  bg-transparent border-b outline-none text-slate-200 text-xl p-2"
            style={{ fontSize: '1.5rem', height: '2.5rem' }}
            placeholder="Email..."
          />
          <input
            type="textarea"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            className="sm:w-4/5 w-full mt-3   bg-transparent border-b outline-none text-slate-200 text-xl p-2"
            style={{ fontSize: '1.5rem', height: '2rem' }}
            placeholder="Message..."
          />

          <button
            onClick={() => handleSendMsg("contribution")}
            className=" mx-auto mt-3 flex px-5 py-2 rounded-md btn-primary"
          >
            <div className="mr-2  ">Send Msg</div>
            <div className="p-1"><FiArrowRight />  </div>
          </button>
        </div>
      </div>

    </div>
  );
};

export default GetInTouch;
