import React, { useState, useEffect } from 'react';
import ReactMapGL, { NavigationControl } from 'react-map-gl';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { FaMapPin } from "react-icons/fa";

import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = process.env.REACT_APP__MAP_TOKEN;
// mapboxgl.accessToken = process.env.REACT_MAP_TOKEN ;
const DraggablePointMap = ({ coordinates, setCoordinates, address }) => {
  const [viewport, setViewport] = useState({
    width: '100%',
    height: '400px',
    latitude: coordinates.latitude,
    longitude: coordinates.longitude,
    zoom: 16,
  });

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [coordinates.longitude, coordinates.latitude],
      zoom: 14,
      attributionControl: false,
        });

    map.on('move', () => {
      const { lng, lat } = map.getCenter();

      setCoordinates({ longitude: lng, latitude: lat });
    });
  }, []); // Only initialize the map on component mount

  const handleViewportChange = (newViewport) => {
    setViewport(newViewport);
    const { lng, lat } = newViewport.center;
    setCoordinates({ longitude: lng, latitude: lat });
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '400px' }}>
      <div id="map" style={{ width: '100%', height: '400px' }}></div>
      <FaMapPin
  style={{
    position: 'absolute',
    top: 'calc(50% - 18px)', // Set top to 50% minus 12px
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 10,
    fontSize: '36px',  // Adjust the size as needed
    color: 'darkblue',  // Change the color to your desired dark color
  }}
/>



    </div>
  );
};

export default DraggablePointMap;
