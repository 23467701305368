// import { faqs } from "../data/dummyData";
import SingleFaq from "../components/singleFaq/singlefaq";
import { CTA } from "../components/property";
import { request } from "../features/featchApi";
import { useState, useEffect } from "react";

const Faqs = () => {
  const [faqs,setFaqs] = useState([]);

  useEffect(()=>{
    
    const fetchabout = async() => {
     try {
       const data = await request('/faqs', 'GET')
       setFaqs(data[0].faqs)
     } catch (error) {
       console.error(error.message)
     }
    }
    fetchabout();
}, [])


  return (
    <div className="pt-20 px-[3%] md:px-[6%]">
      <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 md:grid-cols-3">
        <div className="md:col-span-2">
        {faqs.map((faq) => (
        <SingleFaq {...faq} key={faq.id} />
      ))}
        </div>
        <div className="md:col-span-1">
          <div className="mt-5">
            <CTA/>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
