import React from "react";
// import BlogImage1 from "./images/blog-image-1.jpg";
// import BlogImage2 from "./images/blog-image-2.jpg";

function BlogPage() {
  return (
    <div className="container mx-auto py-28 px-5 md:px-60 dark:text-white">
      <h1 className="text-4xl font-bold mb-6">Earn by Listing Properties Of Others</h1>
      <div className="flex justify-between mb-6">
        <p className="text-sm sm:text-lg text-gray-400 dark:text-gray-300">Praveen Gujjar || February 20, 2024</p>
      </div>
      <p className="mb-6 text-lg">
        Are you looking to make some extra cash? Do you have access to information about available properties in your area? If so, you could earn Rs 500 by simply listing properties that others might be interested in renting. Here’s how:
      </p>
      <img src={"https://lh3.googleusercontent.com/d/1Rf6WtjOyz3Qxe0wYOrVBBfF06K-6H0jh=s1000"} alt="Nature 1" className="mb-6 rounded-lg shadow-lg" />

      <h1 className="text-2xl font-bold mb-3">Listing Flats You Rejected While Searching for Yourself:</h1>
      <p className="mb-6 text-lg">
        You’ve been on the hunt for the perfect place to call home, and while you may have passed on some options, others might find them appealing. By listing these flats along with your contact details, interested individuals can reach out to you if they wish to rent them. Remember, what didn’t work for you might be exactly what someone else is looking for.
      </p>

      <h1 className="text-2xl font-bold mb-3">
      Listing Empty Flats in Your Neighborhood:
        </h1>
      <p className="mb-6 text-lg">
      Have you noticed any vacant flats in your building or neighborhood? These properties represent an opportunity for you to earn some extra income. By listing them and providing your contact information, you can connect potential tenants with these available spaces. It’s a win-win situation – you help someone find a place to live while earning a commission in the process.
      </p>

      <h1 className="text-2xl font-bold mb-3">
      Listing Your Own Flat if You’re Leaving It:
        </h1>
      <p className="mb-6 text-lg">
      If you’re planning to move out of your current residence, why not earn some money in the process? By listing your flat for rent and providing your contact details, you can attract potential tenants who are searching for a place to live. This way, you not only find someone to take over your lease but also earn a commission for facilitating the transaction.
      </p>
      <img src={"https://lh3.googleusercontent.com/d/1Ai1bTCaS0aIJkVWuKAwnxo-ZcEaCA_KO"} alt="Nature 2" className="mb-6 rounded-lg shadow-lg" />
      <h1 className="text-2xl font-bold mb-3">
      How It Works:
        </h1>
      <p className="mb-6 text-lg">
      When you list a property, interested individuals, or "Nyapata," will contact you directly to inquire about the available space. You can provide them with any necessary information and arrange for them to view the property if needed. If they decide to rent the property, you can negotiate your commission directly with the property owner or act as a broker to facilitate the rental process. 
</p>

      <p className="mb-6 text-lg">
      So, if you’re looking to earn some extra income, why not take advantage of your knowledge of available properties in your area? By listing properties and connecting potential tenants with their future homes, you can earn Rs 500 for each successful rental transaction. Get started today and start earning!
</p>

    </div>
  );
}

export default BlogPage;
