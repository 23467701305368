import { GiThreeFriends } from "react-icons/gi";
import { FaQuoteRight } from "react-icons/fa";
import { BsFillPersonFill } from "react-icons/bs";
import { FaLink } from "react-icons/fa";
import { useState, useEffect } from "react";
import { request } from "../features/featchApi";

const AboutU = () => {
  const [testimonials,setTestimonials] = useState([]);
  const [teamMembers,setTeamMembers] = useState([]);
  const [photo1,setPhoto1] = useState([]);
  const [photo2,setPhoto2] = useState([]);
  const [vision,setVision] = useState([]);
  const [praveen,setpraveen] = useState([{}])

  useEffect(()=>{
    
    const fetchabout = async() => {
     try {
       const data = await request('/AboutTestimonials', 'GET')
       setTestimonials(data[0].testimonials)

       const dataone = await request('/aboutteam', 'GET')
       setTeamMembers(dataone[0].team)
      
       const datatwo = await request('/abouts', 'GET')
       setpraveen(datatwo)
       setPhoto1(datatwo[0].photo1)
       setPhoto2(datatwo[0].photo2)
       setVision(datatwo[0].vision)
       

       
     } catch (error) {
       console.error(error.message)
     }
    }
    fetchabout();
}, [])



  return (
    <div className="pt-20 px-[3%] md:px-[6%]">

      {/* Vision */}
      <div className="pt-6 pb-10">
        <div className="text-center max-w-[400px] mx-auto">
          <h1 className="mx-auto sub-heading">Mission</h1>
          <h1 className="heading">{vision}</h1>
        </div>
      </div>

      {/* Team */}
      <div className="pt-10 pb-10">
        <div className="text-center">
          <h1 className="mx-auto sub-heading">our team</h1>
          <h1 className="heading">meet with our  team</h1>
        </div>
        <div>
          <div className="grid grid-cols-2 gap-3 mt-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {teamMembers.slice(0, 4).map(({ id, name, role, image }) => (
              <div className="h-[150px] w-[150px] sm:h-[250px] sm:w-full mb-16" key={id}>
                <img
                  src={image}
                  alt={name}
                  className="object-cover w-full h-full rounded-lg"
                />
                <div className="relative z-10 w-4/5 p-2 mx-auto -mt-10 text-center bg-white rounded-lg shadow-md dark:bg-dark-light">
                  <h1 className="text-lg font-semibold">{name}</h1>
                  <p>{role}</p>

                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* About me */}
      <div className="pt-16 pb-20">
        <div className="flex flex-wrap gap-24">
          <div className="relative flex-1 basis-[18rem] border">
            <img
              src={photo2}
              alt="Nyapata Founder Praveen"
              className="object-cover w-full h-full rounded-lg"
            />
            <img
              src={photo1}
              alt="Praveen Gurjar Nyapata"
              className="absolute object-cover w-48 h-64 border-4 border-white rounded-lg sm:w-72 sm:h-80 dark:border-dark -bottom-20 -right-2 md:-right-20"
            />
          </div>
          <div className="relative flex-1 basis-[22rem]">
            <h1 className="sub-heading">F o u n d e r</h1>
            <h1 className="heading p-1">प्रवीण गुर्जर</h1>
            <p className="mt-3">
              {praveen[0].about}
            </p>
            <div className="mt-4">

            <div className="mt-3 flex-align-center gap-x-2">
                <div className="icon-box text-primary !bg-primary/20">
                <BsFillPersonFill />
                </div>
                <div>
                  <h1 className="font-semibold capitalize">
                  {praveen[0].t1}
                  </h1>
                  <p>
                  {praveen[0].d1}</p>
                </div>
              </div>

              <div className="mt-3 flex-align-center gap-x-2">
                <div className="icon-box text-primary !bg-primary/20">
                <GiThreeFriends />

                </div>
                <div>
                  <h1 className="font-semibold capitalize">
                  {praveen[0].t2}
                  </h1>
                  <p>
                  {praveen[0].d2}</p>                </div>
              </div>

              <div className="mt-3 flex-align-center gap-x-2">
                <div className="icon-box text-primary !bg-primary/20">
                <FaLink />
                </div>
                <div>
                  <h1 className="font-semibold capitalize">
                  {praveen[0].t3}
                  </h1>
                  <p>
                  {praveen[0].d3}</p>
                </div>
              </div>

              

            </div>
          </div>
        </div>
      </div>

      {/* Testimonial */}
      <div className="pt-10 pb-16">
        <div className="text-center">
          <h1 className="mx-auto sub-heading">testimonial</h1>
          <h1 className="heading">what they're saying about our work feedback</h1>
        </div>
        <div className="flex flex-wrap gap-4 mt-8">
          {testimonials.map((testimonial) => (

            <div className="card flex-1 basis-[16rem] relative">
              <div className="absolute opacity-10 text-9xl top-0 left-0">
                <FaQuoteRight />
              </div>
              <p>{testimonial.reviewText}</p>
              <div className="mt-3 flex gap-x-3">
                <img src={testimonial.image} alt={testimonial.name} className="w-10 h-10 rounded-full" />
                <div>
                  <h1 className="font-semibold capitalize">{testimonial.name}</h1>
                  <p className="text-sm capitalize">{testimonial.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default AboutU;

