import { useState, useEffect } from "react";
import { request } from "../../features/featchApi";

const Brands = () => {
  const [homeb,setHomeb]= useState([])

  useEffect(()=>{
    
    const fetchhome = async() => {
     try {
       const data = await request('/homeb', 'GET')
       setHomeb(data[0])
       
     } catch (error) {
       console.error(error.message)
     }
    }
    fetchhome();
}, [])
  return (
    <div className="pt-6 pb-10">
      <div className="text-center max-w-[400px] mx-auto">
        <h1 className="mx-auto sub-heading">{homeb.subheading}</h1>
        <h1 className="heading">{homeb.heading}</h1>
          <p>
          {homeb.paragraph}
        </p>  
      </div>

    </div>
  );
};

export default Brands;
