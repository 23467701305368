import PropertyUpload from "../components/Upload/Uploadprop";


const Upload = () => {
    return (
        <div>
            <div className="pt-20 px-[3%] md:px-[6%]">
                <PropertyUpload />
            </div>
        </div>
    );
};

export default Upload;
