import React, { useState } from 'react';
import { BiBuildings, BiMap, BiSearch } from 'react-icons/bi';
import { Link, NavLink } from 'react-router-dom';

const Filters = () => {
  const [type, setType] = useState();
  const [lat, setlat] = useState();
  const [lng, setlng] = useState();

  const [location, setLocation] = useState();
  const [suggestions, setSuggestions] = useState([
    { label: 'Delhi Technological University', lat: 77.11658297658089, lng: 28.746268227458  },
    { label: 'Sector 17, Rohini', lat: 77.12010258650935, lng: 28.73717987870376 },
    { label: 'Shahbad Daulatpur', lat: 77.11216650924516, lng: 28.748378529261593 },
  ]);

  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleFlatChange = (e) => {
    setType(e.target.value);
  };

  const handleLocationClick = () => {
    // Show suggestions when the input is clicked
    setShowSuggestions(true);
  };

  const handleLocationChange = (e) => {
    const inputValue = e.target.value;
    setLocation(inputValue);

    // // Filter suggestions based on the input value
    // const filteredSuggestions = suggestions.filter(
    //   (suggestion) =>
    //     suggestion.label.toLowerCase().includes(inputValue.toLowerCase())
    // );

    // // Update the suggestions state
    // setSuggestions(filteredSuggestions);

    // Show suggestions only if there are matches
    // setShowSuggestions(filteredSuggestions.length > 0);
  };

  const handleSuggestionSelect = (selected) => {
    setLocation(selected.label);
    // Handle the selected location's lat and lng
    const { lat, lng } = selected;
    setlat(lat)
    setlng(lng)
    // Perform any additional logic or state updates as needed
    setShowSuggestions(false); // Hide suggestions after selection
  };

  return (
    <div className="md:max-w-[70%] w-full mx-auto relative -mt-8 sm:-mt-20">
      <div className="bg-white gap-x-4 flex-center-between gap-y-4 md:gap-y-0 md:flex-row card card-shadow dark:shadow-none">
        {/* PROPERTY TYPE */}
        <div className="w-20 p-2 rounded-lg md:w-fit bg-slate-100 dark:bg-hover-color-dark card-bordered">
          <h1 className="font-bold">Type</h1>
          <div className="flex-align-center gap-x-2">
            <BiBuildings />
            <select
              name="PgFlat"
              onChange={handleFlatChange}
              className="w-full bg-transparent border-0 outline-none dark:bg-hover-color-dark opacity-70"
            >
              <option value="undefined">PG/FLAT</option>
              <option value="PG">PG</option>
              <option value="Flat">FLAT</option>
            </select>
          </div>
        </div>
        {/* LOCATION */}
        <div className="flex flex-1 w-full p-2 pb-0 rounded-lg md:w-fit bg-slate-100 dark:bg-hover-color-dark card-bordered">
          <div className="flex-1">
            <h1 className="font-bold">Location</h1>
            <div className="flex-align-center gap-x-2">
              <BiMap className="" />
              <input
                type="text"
                value={location}
                onClick={handleLocationClick}
                onChange={handleLocationChange}
                className="w-full bg-transparent focus:outline-none hover:bg-transparent mb-0 relative z-10 font-bold"
                placeholder="Start typing your locality .."
              />
            </div>
            {showSuggestions && (
              <div className="absolute z-10  top-full left-0 mt-1 p-2 bg-white dark:bg-hover-color-dark rounded-lg shadow-md w-full">
                {suggestions.map((suggestion) => (
                  <div
                    key={suggestion.label}
                    className="cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800 p-2 rounded"
                    onClick={() => handleSuggestionSelect(suggestion)}
                  >
                    {suggestion.label}
                  </div>
                ))}
              </div>
            )}
          </div>
          <a
            href={`/property/`+type+"/"+lat+"/"+lng} // You might need to adjust the URL structure
            className="grid my-1 mx-2 flex-0 rounded-full w-9 h-9 place-items-center text-white bg-primary sm:cursor-pointer"
          >
            <BiSearch className="text-muted" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Filters;
