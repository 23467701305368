import React, {useRef } from "react";
import { FaTrash, FaEdit } from 'react-icons/fa';

function MediaUploader({files,setFiles}) {
  const fileInputRef = useRef(null);
  const replaceIndex = useRef(null); // Reference to the index of the media to replace

  const handleFileChange = (e) => {
    let selectedFiles = Array.from(e.target.files);
    // If it's the first file and it's not an image, filter it out
    if (files.length === 0 && !selectedFiles[0].type.startsWith('image/')) {
      selectedFiles = selectedFiles.filter((file, index) => index !== 0);
    }

    if (selectedFiles.length > 0) {
      const updatedFiles = [...files];
      if (replaceIndex.current !== null) {
        // If a replaceIndex is set, replace the media at that index
        updatedFiles[replaceIndex.current] = selectedFiles[0];
        replaceIndex.current = null; // Reset the replaceIndex
      } else {
        // If no replaceIndex, just add the selected files
        updatedFiles.push(...selectedFiles);
      }

      setFiles(updatedFiles.slice(0, 10));
      console.log(updatedFiles)
    }
  };

  const handleFileDelete = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleMediaClick = (index) => {
    if (index < files.length) {
      replaceIndex.current = index; // Set the index to replace
      fileInputRef.current.click();
    }
  };



  return (
    <div className="container mx-auto px-4 pb-8 pt-4">
      <h1 className="">Upload Images/Videos</h1>
      <div className="flex flex-wrap justify-center">
        {files.slice(0, 10).map((file, index) => (
          <div key={index} className="w-32 h-32 p-2 relative">
            {file.type.startsWith('image/') ? (
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                className="w-full h-full object-cover rounded-lg cursor-pointer"
                onClick={() => handleMediaClick(index)}
              />
            ) : (
              <video
                src={URL.createObjectURL(file)}
                alt={file.name}
                className="w-full h-full object-cover rounded-lg cursor-pointer"
                onClick={() => handleMediaClick(index)}
                controls
              />
            )}
            <div className="text-white flex-align-center gap-x-2">
              <div onClick={() => handleFileDelete(index)} className="icon-box !w-7 !h-7 bg-primary hover:bg-secondary !opacity-100">
                <FaTrash />
              </div>
              <div onClick={() => handleMediaClick(index)} className="icon-box !w-7 !h-7 bg-primary hover:bg-secondary !opacity-100">
                <FaEdit />
              </div>
            </div>
          </div>
        ))}
        {files.length < 10 && (
          <div className="w-32 h-32 p-4 border-dashed border-4 border-gray-400 rounded-lg flex items-center justify-center">
            <label htmlFor="file-input" className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-16 w-16 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              <input
                ref={fileInputRef}
                id="file-input"
                type="file"
                accept="image/*,video/*"
                onChange={handleFileChange}
                multiple
                className="hidden"
              />
            </label>
          </div>
        )}
      </div>
    </div>
  );
}

export default MediaUploader;
