import Pagination from "../Pagination";
import PropertyFullWidth from "./PropertyFullWidth";
import { useState,useEffect } from "react";
import { request } from "../../../features/featchApi";

const FinalDelte = () => {
  const [property,setProperties]=useState([])

    const RecivePropertiesBack = async () => {
      try {
        const data = await request("/finaldlt", 'GET')
        setProperties(data)
      } catch (error) { 
        console.error(error.message)
      }
    }

    useEffect(()=>{
      RecivePropertiesBack();
  }, [])

  return (
    <div className="pt-20 px-[3%] md:px-[6%]">
      <PropertyFullWidth />
      <Pagination itemsPerPage={6} pageData={property} />
    </div>
  );
};

export default FinalDelte;
