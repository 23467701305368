import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { request } from "../features/featchApi";
import { useEffect } from "react";

const Categories = () => {

  const [categories, setCategories] = useState([]);
  const [Areafounder, setAreafounder] = useState([]);

  useEffect(() => {

    const fetchbroker = async () => {

      try {
        const data = await request('/BrokersB', 'GET');
        setCategories(data);

        const dataa = await request('/Founders', 'GET');
        setAreafounder(dataa);

      } catch (error) {
        console.error(error.message)
      }
    }
    fetchbroker();
  }, [])
  const categoryContainer = useRef(null);


  return (
    <div className="pt-24 px-5 space-y-16">
      <div className="grid grid-cols-1 gap-16 sm:grid-cols-2 md:grid-cols-4">
        <div className="md:col-span-1">
          <h1 className="sub-heading">Top brokers</h1>
          <h1 className="heading">
            Find Top Real Estate Agents on Nyapata
          </h1>
          <p className="mt-3">
          </p>
        </div>

        <div className="md:col-span-3 ">


          <div
            className="gap-3 mt-4 overflow-auto flex-align-center scroll-smooth hide-scrollbar"
            ref={categoryContainer}
          >
            {categories.map((categorie) => (
              <div
              className="relative flex-shrink-0 w-[200px] h-[200px] group rounded-lg overflow-hidden"
            >
              <Link to={"/Channel/" + categorie._id} className="!opacity-100">
                <div className="overflow-hidden object-cover rounded-lg">
                  
                  <img
                    src={categorie.photo.slice(0, -6) + "=s400"}
                    alt={categorie.user}
                    className="w-full h-full object-cover group-hover:scale-125 transition-a"
                  />

                </div>
                <div className="absolute bottom-0 left-0 w-full px-2 py-2 transition-transform bg-gradient-to-t from-black/80 text-slate-100 to-transparent">
                  <h1 className="text-lg font-semibold">{categorie.user}</h1>
                  <p>{categorie.Subt}</p>
                </div>
              </Link>
            </div>
            
            ))}
          </div>

        </div>
      </div>

      <div className="grid grid-cols-1 gap-16 sm:grid-cols-2 md:grid-cols-4">
        <div className="md:col-span-1">
          <h1 className="sub-heading">Area Founders</h1>
          <h1 className="heading">
            Area Founders of Nyapata
          </h1>
          <p className="mt-3">
          </p>
        </div>

        <div className="md:col-span-3 ">



          <div
            className="gap-3 mt-4 overflow-auto flex-align-center scroll-smooth hide-scrollbar"
            ref={categoryContainer}
          >
            {Areafounder.map((categorie) => (
              <div
              className="relative flex-shrink-0 w-[200px] h-[200px] group rounded-lg overflow-hidden"
            >
              <Link to={"/Channel/" + categorie._id} className="!opacity-100">
                <div className="overflow-hidden object-cover rounded-lg">
                  
                  <img
                    src={categorie.photo.slice(0, -6)+ "=s400"}
                    alt={categorie.user}
                    className="w-full h-full object-cover group-hover:scale-125 transition-a"
                  />

                </div>
                <div className="absolute bottom-0 left-0 w-full px-2 py-2 transition-transform bg-gradient-to-t from-black/80 text-slate-100 to-transparent">
                  <h1 className="text-lg font-semibold">{categorie.user}</h1>
                  <p>{categorie.Subt}</p>
                </div>
              </Link>
            </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  );
};

export default Categories;
