import SingleProductCard from "../../Property card/SingleProductCard";
import { useSelector } from "react-redux";
import { dataStore } from "../../../features/dataSlice";
const PropertyList = ({ basis }) => {
  const { currentDataItems } = useSelector(dataStore);

  return (
    <div className="flex flex-wrap gap-4">
      {currentDataItems?.map((property) => (
        <SingleProductCard propertyy={property} key={property._id} {...property} basis={basis} />
      ))}
    </div>
  );
};

export default PropertyList;
