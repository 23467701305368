
import { FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { request } from "../../features/featchApi";

const Footer = () => {
  const [formData, setFormData] = useState({
    email: "",
  });

  useEffect(() => {
    // Your useEffect code here (if needed)
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const isValidEmail = (email) => {
    // Your email validation logic here
    return true; // Placeholder for validation, customize as needed
  };

  const sendDataToBackend = async () => {
    if (!isValidEmail(formData.email)) {
      // Handle invalid email
      alert("Invalid email address");
      return;
    }

    try {
      const headers = {
        'Content-Type': 'application/json'
      };

      console.log("printing form data", formData);

      await request("/Subscribe", "POST", headers, formData);

      setFormData({
        email: "",
      });

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="text-slate-200 md:mx-32">
      <footer>
        <div className="flex flex-wrap md:justify-around ">

          <div className="mt-8 basis-[10rem]">
            <Link to="/" className="flex-shrink-0 flex-align-center gap-x-1">
              <img className="h-10 w-10 text-primary" src="/Nyapata.png" alt="Nyapata Logo" />
              <h1 className="hidden md:block">Nyapata</h1>
            </Link>
            <div className="mt-3">
              <p className="text-sm">
                Our Mission Is To Have Every Property In An Area Listed On Our Website
              </p>
              <div className="gap-5 my-6 flex-center-center">
                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <FiFacebook />
                </div>

                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <FaTwitter />
                </div>

                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <FaInstagram />
                </div>

                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <FaLinkedin />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8 basis-[10rem]">
            <h2 className="text-xl font-semibold">Quick Links</h2>
            <div className="flex space-x-10">
              <ul>
                <li className="my-3 text-muted">
                  <Link to="/Broker">Brokers</Link>
                </li>
                <li className="my-3 text-muted">
                  <Link to="/Blog">Blog</Link>
                </li>
                <li className="my-3 text-muted">
                  <Link to="/Broker">Faqs</Link>
                </li>
              </ul>

              <ul>
                <li className="my-3 text-muted">
                  <Link to="/Joinus">Join us</Link>
                </li>
                <li className="my-3 text-muted">
                  <Link to="/aboutus">About us</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-8 mb-10 basis-[10rem] text-center md:text-left ">
            <h2 className="text-xl font-semibold">
              Subscribe to our Newsletter
            </h2>
            <p className="text-sm text-muted">
              Subscribe to be the first one to know about updates. Enter your
              email
            </p>
            <div className="justify-center my-3 flex-align-center">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="px-4 py-[0.35rem] card-bordered dark:shadow-none outline-none bg-transparent rounded-lg border-dark"
                placeholder="Email Address.."
              />
              <button type="button" onClick={sendDataToBackend} className="-ml-2 btn btn-primary">subscribe</button>
            </div>
          </div>
        </div>
      </footer>

      <div className="py-2 mt-3 text-center border-t border-dark text-muted ">
        <p>
          Created By <span className="text-primary">Praveen Gurjar</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
