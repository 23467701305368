import { Link } from "react-router-dom";
const Hero = () => {


  return (
    <div
      className="relative z-0 flex-wrap min-h-fit gap-2 md:-mt-10 flex-center-center"
      style={{
        background: "url('/images/hero-bg-pattern.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    >
    <div className="absolute top-0 right-0 rounded-full bg-[#04a7ff]/30 dark:bg-[#04a7ff]/50 w-72 h-72 -z-10 blur-[120px]"></div>
      
      <div className="content-center pb-32 pt-14	">
      <div className="space-y-96	"></div>
        <h1 className="text-4xl font-bold capitalize md:text-5xl">
          Har pata,
        </h1>
        <h1 className="text-4xl font-bold capitalize md:text-5xl">
          Hume paata hai
        </h1>
        <Link to="/upload" >
        <button className="mt-6 btn btn-primary">Post Property​​​​​</button>
        </Link>
        <div className=""></div>
      </div>

    </div>
  );
};

export default Hero;
