import React from 'react'
import { useEffect, useState } from "react";

import Footer from './Footer'
import NewsLetter from './NewsLetter'
import BackToTopButton from './BackToTopButton'

function Footerh() {
    const [showButton, setShowButton] = useState(false);
    window.addEventListener("scroll", () => {
        window.scrollY > 500 ? setShowButton(true) : setShowButton(false);
      });

  return (
    <div>
      <div className="mt-40"></div>
      <div className="px-[2%] md:px-[6%] bg-card-dark border border-card-dark">
        <NewsLetter />
        <div className="mt-20">
          <Footer />
        </div>
      </div>
      <BackToTopButton showButton={showButton} />
    </div>
  )
}

export default Footerh