import { useState, useEffect } from "react";
import { request } from "../../features/featchApi";
import { FiUsers, FiLayers } from "react-icons/fi";
import { Link } from "react-router-dom";
const Featured = () => {

  const [categories, setCategories] = useState([]);

  useEffect(() => {

    const fetchbroker = async () => {

      try {
        const data = await request('/BrokersB', 'GET');
        setCategories(data);

      } catch (error) {
        console.error(error.message)
      }
    }
    fetchbroker();
  }, [])

  const [areas,setAreas]= useState([])

  useEffect(()=>{
    
    const fetchhome = async() => {
     try {
       const data = await request('/homeareas', 'GET')
       setAreas(data[0].areas)
       
     } catch (error) {
       console.error(error.message)
     }
    }
    fetchhome();
}, [])

  return (
    <>
      {/* Areas */}
      <div className="pt-16 overflow-hidden">
        <h1 className="text-center mx-auto sub-heading">A r e a s</h1>
        <div className="mt-8 grid grid-cols-2 gap-6 py-4 sm:grid-cols-2 md:grid-cols-5 max-h">
          {areas.map(({link="#", heading, subheading, photo }) => (
            <a href={link} style={{boxshadow: "none"}} className="flex items-center space-x-2">
              <img
                src={photo}
                alt={heading}
                className="w-20 h-20 m-1 rounded-lg object-cover"
              />
              <div className="w-1/2   ">
                <h2 className="text-lg font-bold mb-1 x whitespace-nowrap overflow-ellipsis overflow-hidden">{heading}</h2>
                <p className="text-sm overflow-ellipsis overflow-hidden"> {subheading} </p>
              </div>
            </a>
          ))}
        </div>
      </div>

      {/* Start Nyapata in your area */}
      <div className="justify-around pt-16 pb-10">
        <h1 className="text-center mx-auto sub-heading">AREA FOUNDER</h1>
        <h1 className="text-center heading">Start Nyapata in Your Area</h1>
        <div className="md:flex flex-row items-center mt-8">
        <div className="p-3 bg-white rounded-lg shadow-md w-72 flex-center-between gap-x-3 dark:bg-dark-light">
          <h1>Become the Area Founder of Nyapata</h1>
          <div className="icon-box text-primary !bg-primary/20">
            <FiUsers />
          </div>
        </div>
        <div className="p-3 my-4 ml-8 bg-white rounded-lg shadow-md w-72 flex-center-between gap-x-3 dark:bg-dark-light">
          <h1>You will be holding primary ownership for the region</h1>
          <div className="icon-box text-primary !bg-primary/20">
            <FiLayers />
          </div>
        </div>
        <div className="p-3 md:ml-8 bg-white rounded-lg shadow-md w-72 flex-center-between gap-x-3 dark:bg-dark-light">
        <h1>Decisions and Profits will be yours for that region</h1>

          <div className="icon-box text-primary !bg-primary/20">
            <FiUsers />
          </div>
        </div>
        <div className="p-3 mt-4 ml-8 bg-white rounded-lg shadow-md w-72 flex-center-between gap-x-3 dark:bg-dark-light">
        <h1>You can earn as much as the top broker in your area.</h1>
          <div className="icon-box text-primary !bg-primary/20">
            <FiLayers />
          </div>
        </div>

      </div>
      </div>

      <div className="pt-16 overflow-hidden">
        <h1 className="text-center mx-auto sub-heading">B r o k e r s</h1>
        <div className="mt-8 grid grid-cols-2 gap-6 py-4 sm:grid-cols-2 md:grid-cols-5 max-h">
          {categories.map((categorie) => (
            <Link to={"/Channel/" + categorie._id}style={{boxshadow: "none"}} className="flex items-center space-x-2">
              <img
                src={categorie.photo}
                alt={categorie.user}
                className="w-20 h-20 m-1 rounded-lg object-cover"
              />
              <div className="w-1/2   ">
                <h2 className="text-lg font-bold mb-1 x whitespace-nowrap overflow-ellipsis overflow-hidden">{categorie.user}</h2>
                <p className="text-sm overflow-ellipsis overflow-hidden"> {categorie.Subt} </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Featured;
