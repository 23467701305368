import { useEffect, useState } from "react";
import { request } from "../../features/featchApi";
  
const Counter = () => {
  const [CountUser,setCountUser]= useState()
  const [Countstbr,setCountstbr]= useState()
  const [CountPg,setCountPg]= useState()
  const [CountFlat,setCountFlat]= useState()

  useEffect(()=> {
    const numberprop = async()=>{
    try {
      const usercount = await request('/usercounting','GET');
      const propcount = await request('/propcounting','GET');
      setCountUser(usercount.usercount);
      setCountstbr(usercount.brcount);
      setCountPg(propcount.countpg);
      setCountFlat(propcount.countflat);


    } catch (error) {
      console.error(error.message)
    }}
    numberprop();
  },[])


  return (
    <div className="flex-wrap justify-center gap-4 px-4 py-8 flex-align-center sm:justify-between bg-secondary">
      
      <div className="text-center">
        <h1 className="heading !text-slate-100">{CountUser}+</h1>
        <p className="text-slate-100">Users Registred</p>
      </div>
      
      <div className="text-center">
        <h1 className="heading !text-slate-100">{Countstbr}+</h1>
        <p className="text-slate-100">Brokers</p>
      </div>
      
      <div className="text-center">
        <h1 className="heading !text-slate-100">{CountPg}+</h1>
        <p className="text-slate-100">Pg Registred</p>
      </div>

      <div className="text-center">
        <h1 className="heading !text-slate-100">{CountFlat}+</h1>
        <p className="text-slate-100">Flat Registred</p>
      </div>

    </div>
  );
};

export default Counter;
