
import {
  Brands,
  Counter,
  Featured,
  Testimonial,
} from "../components/Home"
import {
  Filters,
  Hero,
} from "../components/Home";

const Home = () => {

  return (
    <div className="pt-16 px-[3%] md:px-[6%]">
      <Hero />
      <Filters />
      
      <Featured/>

      <Testimonial />
      <Counter />
      <Brands />
    </div>
  );
};

export default Home;
