import { request } from "../features/featchApi";
import { MdCall } from "react-icons/md";
import { FaMapMarkedAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { useEffect, useState } from "react";
import {
    Pagination,
} from "../components/property";
import { PropertyList } from "../components/property";
import { useParams } from "react-router-dom";
import BrokersSite from "./BrokersSite";
function ProfilePage({ preview = "https://lh3.googleusercontent.com/d/1Cc8kr4_ZQYOd_BFICr865oS9wu4oN1i4" }) {

    const { id } = useParams();
    const {btn} = useParams();
    const [property, setProperties] = useState([]);
    const [user, setUser] = useState({});
    const RecivePropertiesBack = async () => {
        try {
            let link = "/getuser/" + id;
            const data = await request(link, 'GET')
            setUser(data)
            const daata = await request("/find/" + id, 'GET')
            setProperties(daata)
        } catch (error) {
            console.error(error.message)
        }
    }
    useEffect(() => {
        RecivePropertiesBack()
    },[])





    const handleCallClick = () => {
        if (user.phone) {
          // Call functionality using the phone variable
          window.location.href = `tel:${user.phone}`;
        }
    }

    const handleWhatsClick = () => {
        if (user.whatsapp) {
          // Call functionality using the phone variable
          window.location.href = `https://wa.me/+91${user.whatsapp}`;
        }
    }




    return (
        <>
        {user.utype === "b"?
        <BrokersSite property={property} user={user} handleCallClick={handleCallClick} handleWhatsClick={handleWhatsClick} btn={btn} id={id} />
        :   
            <></>}
        {user.utype === "n"?
        <div className="grid md:grid-cols-4 gap-x-14  mx-5">
        <div className="h-24 sticky top-0 z-10 md:col-span-5 bg-white dark:bg-main-dark">

        </div>

        <div className="  md:col-span-5 card flex-1 basis-[16rem] relative">

            <div className="w-full  h-24 sm:h-32 overflow-hidden flex items-center justify-center">
                <img src={user.thumb === '' ? preview : user.thumb} alt={user.user} className="object-cover w-full" />
            </div>

        </div>

        <div className=" card md:col-span-5 sticky top-20 z-20  flex gap-x-3 pb-5">

            <img src={user.photo} alt={user.user} className="w-20 h-20 rounded-full" />

            <div className=" items-center justify-center">
                <h1 className="font-semibold heading capitalize">{user.user}</h1>
            </div>

        </div>

        <div className="h-8 sticky top-44 z-10 md:col-span-5 bg-white dark:bg-main-dark">
        </div>


        <div className="md:col-span-5 mt-5 md:mt-0 h-fit md:sticky top-0 ">
            <PropertyList />
            <Pagination itemsPerPage={10} pageData={property} />
        </div>

    </div>
    :
    <></>
        }
        {user.utype === "f"?
        <div className="grid md:grid-cols-4 gap-x-14  mx-5">
        <div className="h-24 sticky top-0 z-10 md:col-span-5 bg-white dark:bg-main-dark">

        </div>

        <div className="  md:col-span-5 card flex-1 basis-[16rem] relative">

            <div className="w-full  h-24 sm:h-32 overflow-hidden flex items-center justify-center">
                <img src={user.thumb === '' ? preview : user.thumb} alt={user.user} className="object-cover w-full" />
            </div>

        </div>

        <div className=" card md:col-span-5 sticky top-20 z-20  flex gap-x-3 pb-5">

            <img src={user.photo} alt={user.user} className="w-20 h-20 rounded-full" />

            <div className=" items-center justify-center">
                <h1 className="font-semibold heading capitalize">{user.user}</h1>
            </div>

        </div>

        <div className="h-8 sticky top-44 z-10 md:col-span-5 bg-white dark:bg-main-dark">
        </div>


        <div className="md:col-span-5 mt-5 md:mt-0 h-fit md:sticky top-0 ">
            <PropertyList />
            <Pagination itemsPerPage={10} pageData={property} />
        </div>

    </div>
    :
    <></>
        }
        </>

    );
}

export default ProfilePage;
