const cardLabels = ({ Furnishing, purpose, distance, Security }) => {
  return (
    <>
      <div className="absolute top-2 left-2 flex-align-center gap-x-2">
        <span className="py-[3px] px-3 text-sm rounded-full text-white bg-primary">
          {distance} meter
        </span>
        <span className="py-[3px] px-3 text-sm rounded-full capitalize text-white bg-secondary">
          {purpose}
        </span>
        {purpose = "PG" ?
          <>
            <span className="py-[3px] px-3 text-sm rounded-full capitalize text-white bg-secondary">
              {Furnishing === "f" ? "Furnished" : <></>}
              {Furnishing === "s" ? "Semi-Furnished" : <></>}
              {Furnishing === "u" ? "UnFurnished" : <></>}
            </span>
          </> :
          <>
          </>}
      </div>

      {Security === 0 ?
        <div className="absolute bottom-14 left-2 flex-align-center gap-x-2">
          <span className="py-[3px] px-3 text-sm rounded-full text-white bg-secondary">
            No Security Deposite
          </span>
        </div>
        : <></>}
    </>
  );
};

export default cardLabels;
