import React, { useState } from "react";
import { FaRegHeart } from "react-icons/fa";
import { VscSave } from "react-icons/vsc";
import { MdEdit } from "react-icons/md";
import { request } from "../features/featchApi";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

function ProfilePage({preview="https://lh3.googleusercontent.com/d/1Cc8kr4_ZQYOd_BFICr865oS9wu4oN1i4" }) {

const [user,setUser]=useState({})
const token = localStorage.getItem('token');
const navigate = useNavigate();

const RecivePropertiesBack = async (token) => {
  try {
    const header ={
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
    console.log("called")
    const data = await request("/finduser", 'GET',header)
    setUser(data)
  } catch (error) {
    console.error(error.message)
  }
}

if (!user.user && token) {
  console.log(user.user,token);
  (async () => {
    await RecivePropertiesBack(token);
    await RecivePropertiesBack(token);
  })();
}


useEffect(() => {
  if (localStorage.getItem('tokenExpiryTime') < Date.now())
    {
    localStorage.removeItem('token');
    localStorage.removeItem('accesstoken');
    localStorage.removeItem('tokenExpiryTime');}
  });

useEffect(() => {
  // Check if token is available in localStorage
  const token = localStorage.getItem('token');

  if (!token) {
    navigate('/login'); // Change '/login' to your login page route
  }
});

const clearToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('accesstoken');
  localStorage.removeItem('tokenExpiryTime');
};

  return (
    <>
    <div className="mt-20 card sm:w-1/3 sm:m-auto sm:mt-20 flex-1 basis-[16rem] relative m-2">
    
      <div className="w-full h-24 overflow-hidden flex items-center justify-center">
      
      <div className="right-0 absolute p-3 w-10 h-10 rounded-full bg-white/80 backdrop-blur-sm dark:bg-dark-light/60 ">
            <h1 className=" text-secondary dark:text-slate-300">
              <MdEdit/>
            </h1>
          </div>

      <img src={user.thumb === '' ? preview : user.thumb} alt={user.user} className="object-cover w-full" /></div>

      <div className="mt-5 flex gap-x-3">
        <img src={user.photo} alt={user.user} className="w-20 h-20 rounded-full" />
        <div className=" items-center justify-center">
          <h1 className="font-semibold heading capitalize">{user.user}</h1>
          <p  className="text-sm capitalize">Profile Editing comming soon..</p>
        </div>
      </div>


      <div className="flex space-x-4 mt-3  w-full"> 
      <div className="p-2 w-full text-center rounded-full px-4 bg-white/80 backdrop-blur-sm dark:bg-dark-light/60 ">
            <Link to={"/channel/"+ user._id} className=" text-secondary dark:text-slate-300">
              View Your Website Page
            </Link>
          </div>

          <div className=" p-3 w-10 h-10 text-center rounded-full bg-white/80 backdrop-blur-sm dark:bg-dark-light/60 ">
            <h1 className=" text-secondary dark:text-slate-300">
              <FaRegHeart/>
            </h1>
          </div>

          <div className=" p-3 w-10 h-10 text-center rounded-full bg-white/80 backdrop-blur-sm dark:bg-dark-light/60 ">
            <h1 className=" text-secondary dark:text-slate-300">
            <VscSave/>
            </h1>
          </div>    

          </div>
          <div className="mt-2 p-2 w-full text-center rounded-full px-4 bg-white/80 backdrop-blur-sm dark:bg-dark-light/60 ">
            <h1 className=" text-secondary dark:text-slate-300">
              Edit Profile
            </h1>
          </div>

          <div className="mt-2 p-2 w-full text-center rounded-full px-4 bg-white/80 backdrop-blur-sm dark:bg-dark-light/60 ">
            <h1 className=" text-secondary dark:text-slate-300">
              Become Broker
            </h1>
          </div>

          <div className="mt-2 p-2 w-full text-center rounded-full px-4 bg-white/80 backdrop-blur-sm dark:bg-dark-light/60 ">
          <Link to={"/OwnProperties/"+ user._id + "/" + user.user} className=" text-secondary dark:text-slate-300">
              Manage Your Properties
            </Link>
          </div>

          <div onClick={clearToken} className="mt-2 p-2 w-full text-center rounded-full px-4 bg-white/80 backdrop-blur-sm dark:bg-dark-light/60 ">
            <h1 className=" text-secondary dark:text-slate-300">
              Logout
            </h1>
          </div>

    </div>
    </>
  );
}

export default ProfilePage;
