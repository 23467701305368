import React from 'react';
import { signInWithGoogle } from './GoogleAuthh';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";

const LoginPage = () => {
  const navigate = useNavigate();
  const gotohome = ()=>{
        navigate("/")
  }
  return (
    <div className="fixed top-0 z-20 w-screen h-screen py-8 bg-black/95 bg-opacity-5	">
      <div className="relative container m-auto px-6  md:px-12 xl:px-40">
        <div className="m-auto md:w-8/12 lg:w-6/12 xl:w-6/12">
          <div className="rounded-xl bg-white dark:bg-dark-light shadow-xl">
            <div className="p-6 sm:p-16">
              <div className="space-y-4">
                <img src="/Nyapata.png" loading="lazy" className="w-10" alt="tailus logo" />
                <h2 className="mb-8 text-2xl  font-bold">Sign in to List Properties <br /> on Nyapata.</h2>
                <p>Allow all the permissions to list properties</p>
              </div>
              <div className="mt-16 grid space-y-4">
                <button onClick={async()=> {const user = await signInWithGoogle(); if (user) {navigate("/")}}} className="group h-12 px-6 border-2 border-gray-300 rounded-full transition duration-300 hover:border-blue-400 focus:bg-blue-50 active:bg-blue-100">
                  <div className="relative flex items-center space-x-4 justify-center">
                    <img src="https://tailus.io/sources/blocks/social/preview/images/google.svg" className="absolute left-0 w-5" alt="google logo" />
                    <span className="block w-max font-semibold tracking-wide  text-sm transition duration-300 group-hover:text-blue-600 sm:text-base">Continue with Google</span>
                  </div>
                </button>

                <button onClick={gotohome} className="group h-12 px-6 border-2 border-gray-300 rounded-full transition duration-300 hover:border-blue-400 focus:bg-blue-50 active:bg-blue-100">
                  <div className="relative flex items-center space-x-4 justify-center">
                    <IoArrowBack  className="absolute left-0 w-5" alt="go back" />
                    <span className="block w-max font-semibold tracking-wide  text-sm transition duration-300 group-hover:text-blue-600 sm:text-base">Back to Home Page</span>
                  </div>
                </button>


              </div>

              <div className="mt-32 space-y-4  text-center sm:-mb-8">
                <p className="text-xs">By proceeding, you agree to our <span  className="underline">Terms of Use</span> and confirm you have read our <span className="underline">Privacy and Cookie Statement</span>.</p>
                <p className="text-xs">This site is protected by reCAPTCHA and the <span  className="underline">Google Privacy Policy</span> and <span className="underline">Terms of Service</span> apply.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
