import React, { useEffect } from 'react';
import { gapi } from "gapi-script";
import { request } from '../../features/featchApi';

const clientId = process.env.REACT_APP_GOOGLE_LOGIN
const dataa = {}


const loadGapi = async () => {
   try {
     await new Promise((resolve, reject) => {
       window.gapi.load('auth2', {
         callback: resolve,
         onerror: reject,
       });
     });

     await window.gapi.auth2.init({
       client_id: clientId,
       scope: 'https://www.googleapis.com/auth/drive.file',
       
     });

   } catch (error) {
     console.error('Error loading or initializing gapi:', error);
   }
 };


 export const signInWithGoogle = async () => {
  try {
   loadGapi()
    const auth2 = window.gapi.auth2.getAuthInstance();
    const googleUser = await auth2.signIn();

    // Access user information using googleUser.getBasicProfile() or other methods
    const profile = googleUser.getBasicProfile();
    const token = googleUser.getAuthResponse().access_token;
    
    localStorage.setItem('accesstoken', token);

    dataa.user = profile.getName() ;
    dataa.email = profile.getEmail() ;
    dataa.gid = profile.getId() ;
    dataa.photo = profile.getImageUrl();

    sendBackend();
    return "done";
  } catch (error) {
    console.error('Google Sign-In Error:', error);
  }
};

const sendBackend = async() => {
  try {
      const headers = {
          'Content-Type' : "application/json"
      }
      const data = await request('/register', "POST", headers, dataa)
      localStorage.setItem('token', data.token);
      const tokenExpirationTime = 40 * 60 * 1000;
      localStorage.setItem('tokenExpiryTime', Date.now() + tokenExpirationTime);
      
     
    
      
  } catch (error) {
      console.error(error)
  }
}




const GoogleLoginComponent = ({setAccessToken}) => {
  // const clientId = "806590230778-6himddledgqf5qqlrcd1214m4gkp7bme.apps.googleusercontent.com"; // Replace with your actual Google Client ID



  return (
    <div className='mt-20'>
      <button id='yourButtonId' onClick={signInWithGoogle}>Log in with Google</button>
    </div>
  );
};

export default GoogleLoginComponent;
