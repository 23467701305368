export const navLinks = [
  {
    id: 0,
    linkText: "Home",
    url: "/",
  },

  {
    id: 1,
    linkText: "Brokers",
    url: "/Broker",
  },

  {
    id: 2,
    linkText: "Join Us",
    url: "/JoinUs",
  },

  {
    id: 3,
    linkText: "Blog",
    url: "/Blog",
  },

  {
    id: 4,
    linkText: "Faqs",
    url: "/faqs",
  },


  

  

  {
    id: 5,
    linkText: "About Us",
    url: "/about-us",
  },



];
