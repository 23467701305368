import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./app/store";
import { AnimatePresence } from "framer-motion";
import { persistor } from "./features/store";
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AnimatePresence>
            <App />
          </AnimatePresence>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
