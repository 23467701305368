import React, { useState, useEffect } from 'react';
import MapboxAutocomplete from 'react-mapbox-autocomplete';
import 'tailwindcss/tailwind.css';
import DraggablePointMap from './DraggablePointMap';
import xss from 'xss';
const AdressUpload = ({ property, handleType, setProperty,handlestate }) => {

  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState({
    longitude: 77.11700006703794,
    latitude: 28.745090001507602,
  });

  useEffect(() => {
    property.loc = {
      "type": "Point",
      "coordinates": [
        coordinates.longitude,
        coordinates.latitude
      ]}
        },[coordinates])
  


  return (
    <div className="App">
      <div className="w-full mx-auto">
        <div className='bg-transparent rounded '>
          <div className="mb-4 mt-4">
            <label className="tracking-widest block text-gray-700 dark:text-gray-400 text-sm font-bold mb-2">
              Do you want the address to be shown to the user?*
            </label>
            <div className="flex space-x-2">


              <button
                type="button"
                onClick={() => handleType("Adds", "Yes")}
                className={`inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 border-2 border-gray-200 cursor-pointer dark:text-gray-400 ${property.Adds === "Yes" ? 'border-primary bg-primary/20' : 'dark:border-gray-700 bg-transparent'}`}
              >
                <div className=" block w-full text-sm font-semibold tracking-widest"> Yes </div>
              </button>

              <button
                type="button"
                onClick={() => handleType("Adds", "No")}
                className={`inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 border-2 border-gray-200 cursor-pointer dark:text-gray-400 ${property.Adds === "No" ? 'border-primary bg-primary/20' : 'dark:border-gray-700 bg-transparent'}`}
              >
                <div className=" block w-full text-sm font-semibold tracking-widest"> No </div>
              </button>

            </div>
          </div>
          {(property.Adds === "No") ?
            <label className="tracking-widest block text-gray-700 dark:text-gray-400 text-sm mb-2">
              The address is for distance calculation, not for user display
            </label> : <></>}
          {(property.Adds !== undefined) ? (
          <>

            <DraggablePointMap coordinates={coordinates} setCoordinates={setCoordinates} address={address} />

            <form className='px-8 space-y-2 pt-6 pb-8 z-10'>

              <input onChange={handlestate}  placeholder='House/Flat/Block No. *' name="Addone"  type="Text" className="w-full p-3 h-11 bg-transparent rounded border border-gray-200 focus:outline-none focus:border-primary/40" />
              
              <input onChange={handlestate}   placeholder='Apartment/Road/Area. *' name="Addtwo"  type="Text" className="w-full p-3 h-11 bg-transparent rounded border border-gray-200 focus:outline-none focus:border-primary/40" />

            </form>

          </>
          ) : (<></>)}
        </div>


      </div>

    </div>
  );
}

export default AdressUpload;
