import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaWhatsapp, FaPhone } from 'react-icons/fa';
import { request } from '../../features/featchApi';
import { useEffect } from 'react';
import { useState } from 'react';

function Profile({ handlestate, property, handleType }) {
  const [user,setUser]=useState({})


  const ReciveProfileBack = async () => {
    try {
      const token = localStorage.getItem('token');
      const header ={
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
      
      const data = await request("/finduser", 'GET',header)
      setUser(data);
    } catch (error) {
      console.error(error.message)
    }
  }
  
  useEffect(() => {
    ReciveProfileBack()
  }, [])

  return (
    <div>
      <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 ">
          <li className="py-3 sm:py-4">
            <div className="items-center space-x-4">

              <div className="pl-4 flex-1 min-w-0">
                <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                  {user.user}
                </p>
                <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                  {user.email}
                </p>
              </div>

              <div className="mt-5 text-lg border-primary/80 border-2 font-bold capitalize overflow-hidden sm:cursor-pointer flex-align-center ">

                <div className="h-10 p-3 w-10 bg-primary/80 text-center">
                  <span className="text-white ">
                    <FaPhone />
                  </span>
                </div>
                <input
                  name="Phone"
                  min="6"
                  max="9999999999"
                  onChange={handlestate}
                  onInput={(e) => e.target.validity.valid || (e.target.value = (e.target.value).slice(0, 10))}
                  className="pl-1 h-10 p-1 bg-transparent w-full focus:outline-none"
                  type="Number"
                  placeholder="Phone Number"
                  aria-label="Full name"
                />
              </div>

              <div className="mt-5 text-lg border-primary/80 border-2 font-bold capitalize overflow-hidden sm:cursor-pointer flex-align-center ">

                <div className="h-10 p-3 w- text-lg bg-primary/80 text-center">
                  <span className="text-white  "> <b><FaWhatsapp /></b>
                  </span>
                </div>
                <input
                  min="6"
                  minLength="10"
                  max="9999999999"
                  name="Whatsapp"
                  onChange={handlestate}
                  value={property.Whatsapp}
                  onInput={(e) => e.target.validity.valid || (e.target.value = (e.target.value).slice(0, 10))}
                  className="pl-1 h-10 p-1 bg-transparent w-full focus:outline-none"
                  type="Number"
                  placeholder="WhatsApp Number"
                  aria-label="Full name"
                />
              </div>

              <div className="mb-4 mt-4">
                {/* ... (rest of your code) ... */}
              </div>

            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Profile;
