import { Link } from "react-router-dom";
import CardLabels from "./CardLabels";
const SingleProductCard = ({
  propertyy,
  basis,
}) => {
  const key = propertyy._id;
  
const lat1 = propertyy.loc.coordinates[0];
const lon1 = propertyy.loc.coordinates[1];
function calculateDistance(lat1, lon1, lat2=77.11700140063112, lon2= 28.74516294676884) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = deg2rad(lon2 - lon1); 
    const a = 
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ; 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); 
    const distance = R * c *1000 * 1.5; // Distance in km
    return distance;
  }
  
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }



 const distance =  Math.floor(calculateDistance(lat1,lon1));
  

  return (
    <div
      className={`flex-1 ${
        basis ? basis : "basis-[18rem]"
      } shadow-light dark:border-primary/20 border  rounded-lg overflow-hidden relative group`}
    >
      <div className="group !opacity-100 overflow-hidden relative">
        <Link to={"/details/"+ key + "/"+ propertyy.Title} className="!opacity-100">
          <img
            src={"https://lh3.googleusercontent.com/d/"+ propertyy.images[0] +"=s400"}
            alt={propertyy.Title}
            className="w-full  h-[250px] object-cover group-hover:scale-125 transition-a"
          />
        </Link>

      </div>
      
      
      <CardLabels Furnishing={propertyy.Furnishing}  Security={propertyy.Deposite} purpose={propertyy.PropertyType === 'Flat' ? <>{propertyy.BHK + " BHK"}</> : "PG" } distance={distance} />
      
      <div className="flex p-3 w-full">
  
  <Link to={"/details/"+ key + "/"+ propertyy.Title} className="group-hover:text-primary transition-a flex-1 w-4/5 overflow-hidden">
    <h1 className="text-lg font-bold capitalize overflow-clip whitespace-nowrap max-w-full">
      {propertyy.Title}
    </h1>
  </Link>

  {/* Price */}
  <h1 className="text-lg font-semibold text-primary">₹{propertyy.Rent}</h1>
</div>

    </div>
  );
};

export default SingleProductCard;
