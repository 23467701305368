import React from 'react'
import { FiDelete } from 'react-icons/fi';
import { useState, useEffect } from 'react';
import { request } from '../../features/featchApi';
import { useNavigate } from 'react-router-dom';
import { register } from '../../features/authSlice';
import { useDispatch } from 'react-redux';
function Singup({toggle,login}) {
  const [state,setState] = useState({})
  const dispatch = useDispatch()


  const handlestate= (e) => {
    setState (prev => {
      return {...prev, [e.target.name]: e.target.value}
    })
  }


  const handlesubmit = async (e) => {
    e.preventDefault()
    console.log("function caled")
    try {
      const headers= {
        'Content-Type' : "application/json"
      }
      const dataa = await request("/register", "POST", headers,state)
      dispatch(register(dataa))
      toggle()

      
    } catch (error) {
        console.error(error)
    }
  }
  
  return (
    <div className="bg-black/80 fixed z-20 w-screen h-screen">

    
      <div className="flex items-center min-h-screen p-4  lg:justify-center md:w-1/3 md:m-auto">

        <div className="flex flex-col overflow-hidden dark:bg-dark-light rounded-md shadow-lg md:flex-row md:flex-1 w-full mx-4 sm:w-1/2">
        
          <div className=" relative p-5 bg-white dark:bg-dark-light md:flex-1">
          <svg
                        className="w-5 h-5 m-3 text-black dark:text-white fill-current hover:cursor-pointer absolute right-0 top-0"
                        viewBox="0 0 24 24"
                        version="1.1"
                        aria-hidden="true"
                        onClick={toggle}
                      >
                                <path fillRule="evenodd" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path>V
                      </svg>
          
            <h3 className="my-4 text-2xl font-semibold text-gray-700 dark:text-white/75">

              Account Register
            </h3>


            <form className="flex flex-col space-y-5" onSubmit={handlesubmit}>
              <div className="flex flex-col space-y-1">
                <label
                  htmlFor="email"
                  className="text-sm font-semibold text-gray-500 dark:text-white/50"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  name='user'
                  autofocus=""
                  onChange={handlestate}
                  className="px-4 py-2 transition duration-300 border border-gray-300 bg-transparent rounded focus:outline-none focus:border-primary"
                />
              </div>

              <div className="flex flex-col space-y-1">
                <label
                  htmlFor="email"
                  className="text-sm font-semibold text-gray-500 dark:text-white/50"
                >
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  autofocus=""
                  onChange={handlestate}
                  className="px-4 py-2 transition duration-300 border border-gray-300 bg-transparent rounded focus:outline-none focus:border-primary"
                />
              </div>

              <div className="flex flex-col space-y-1">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="text-sm font-semibold text-gray-500 dark:text-white/50"
                  >
                    Password
                  </label>

                </div>
                <input
                  type="password"
                  id="password"
                  name="password"
                  onChange={handlestate}
                  className="px-4 py-2 transition duration-300 border bg-transparent border-gray-300 rounded  focus:outline-none focus:border-primary"
                />
              </div>


              <div>
                <button
                  type="submit"
                  className="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-primary/75 rounded-md shadow hover:bg-primary focus:outline-none focus:ring-blue-200 focus:ring-4"
                >
                  Register
                </button>
              </div>

              <p className='text-sm text-center'>Already having an account <a onClick={login} className='underline text-primary hover:cursor-pointer'>Login</a></p>
              <div className="flex flex-col space-y-5">
                <span className="flex items-center justify-center space-x-2">
                  <span className="h-px bg-gray-400 w-14" />
                  <span className="font-normal text-gray-500">or Continue with</span>
                  <span className="h-px bg-gray-400 w-14" />
                </span>
                <div className="flex flex-col space-y-4">
                  <a
                    href="#"
                    className="flex items-center justify-center px-4 py-2 space-x-2 transition-colors duration-300 border border-gray-800 rounded-md group hover:bg-gray-800 focus:outline-none"
                  >
                    <span>
                      <svg
                        className="w-5 h-5 text-gray-800 fill-current group-hover:text-white"
                        viewBox="0 0 16 16"
                        version="1.1"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                      </svg>
                    </span>
                    <span className="text-sm font-medium text-gray-800 group-hover:text-white">
                      Google
                    </span>
                  </a>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>



    </div>
  )
}

export default Singup