import React, { useEffect } from 'react'
import { useState } from 'react'
import { request } from '../../features/featchApi'
import { FaWhatsapp } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { IoIosPlayCircle } from "react-icons/io";

function Details() {
  const [currentImage, setCurrentImage] = useState(0);


  const { Propertyid } = useParams();
  const id = Propertyid;


  const [Property, setProperty] = useState({});
  const [user, setuser] = useState({});
  const RecivePropertiesBack = async () => {
    try {
      let link = "/findd/" + id;
      const data = await request(link, 'GET')
      setProperty(data)
      console.log(data)
      let linkk = "/getuser/" + data.currentOwner
      const daata = await request(linkk, 'GET')
      setuser(daata)

    } catch (error) {
      console.error(error.message)
    }
  }

  useEffect(() => {
    RecivePropertiesBack();
  }, [])


  let images = [
    '',
  ];

  if (Property.images) { images = Property.images }

  const handlePrevious = () => {
    setCurrentImage((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentImage((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const [url, seturl] = useState("https://drive.usercontent.google.com/download?id=" + images[0] + "&export=view")
  useEffect(() => {
    const urll = "https://drive.usercontent.google.com/download?id=" + images[currentImage] + "&export=view"
    seturl(urll)
  }, [currentImage])



  return (
    <>
      <Helmet>
        <title>{Property.Title}</title>
        <meta name="description" content={Property.desc} />
        <meta property="og:type" content="website" />
        <meta name="og:title" property="og:title" content={Property.Title} />
        <meta name="og:description" property="og:description" content={Property.desc} />
        <meta property="og:site_name" content="NyaPata" />
        <meta name="og:image" property="og:image" content={"https://lh3.googleusercontent.com/d/" + images[0] + "=s50"} />
      </Helmet>

      {/* Contents */}
      <div className="container mx-auto text-base">
        {/* Navbar */}

        {/* Main Content */}
        <main className=" flex flex-col lg:flex-row">

          {/* Gallery */}
          <section className="sm:w-2/3 -space-y-5 flex-col gap-8 mt-24 sm:flex sm:flex-row sm:gap-4 sm:mt-24 sm:mx-2 md:gap-8 md:mx-4 lg:flex-col lg:mx-0 lg:mt-36">
            <picture className="w-full relative flex items-center bg-orange sm:bg-transparent">
              <button
                className="bg-white w-10 h-10 flex items-center justify-center pr-1 rounded-full absolute left-6 z-10"
                id="previous-mobile"
                onClick={handlePrevious}
              >
                <svg
                  width={12}
                  height={18}
                  xmlns="http://www.w3.org/2000/svg"
                  id="previous-mobile"
                >
                  <path
                    d="M11 1 3 9l8 8"
                    stroke="#1D2026"
                    strokeWidth={3}
                    fill="none"
                    fillRule="evenodd"
                    id="previous-mobile"
                  />
                </svg>
              </button>

              <div className="image-container w-full h-72  overflow-hidden">
                {images[currentImage].slice(0, 5) === "video" ?
                  <>
                    <iframe
                      src={"https://drive.google.com/file/d/" + images[currentImage].slice(5,) + "/preview"}
                      sandbox="allow-same-origin allow-scripts"
                      className="block object-cover w-full h-full sm:rounded-xl  xl:rounded-xl m-auto transition  lg:pointer-events-auto lg:cursor-pointer lg:hover:shadow-xl"
                    ></iframe>

                    <div className="m-3" style={{ position: "absolute", right: 0, top: 0, width: "40px", height: "40px", backgroundColor: "white" }}>
                      <img className="h-full w-full text-primary" src='/Nyapata.png' alt="Nyapata Logo" />
                    </div>
                  </> :
                  <img
                    className="block object-cover w-full h-full sm:rounded-xl  xl:rounded-xl m-auto pointer-events-none transition  lg:pointer-events-auto lg:cursor-pointer lg:hover:shadow-xl"
                    src={"https://lh3.googleusercontent.com/d/" + images[currentImage] + "=s1000"}
                  />}
                {/* <iframe
  src={"https://drive.google.com/file/d/" + images[currentImage] + "/preview"}
  width="640"
  height="480"
  allow="autoplay"
  className="block object-cover w-full h-full sm:rounded-xl xl:w-[70%] xl:rounded-xl m-auto pointer-events-none transition duration-300 lg:w-3/4 lg:pointer-events-auto lg:cursor-pointer lg:hover:shadow-xl"
></iframe> */}


              </div>
              <button
                className="bg-white w-10 h-10 flex items-center justify-center pl-1 rounded-full absolute right-6 z-10"
                id="next-mobile"
                onClick={handleNext}
              >
                <svg
                  width={13}
                  height={18}
                  xmlns="./images/property (2).jpeg"
                  id="next-mobile"
                >
                  <path
                    d="m2 1 8 8-8 8"
                    stroke="#1D2026"
                    strokeWidth={3}
                    fill="none"
                    fillRule="evenodd"
                    id="next-mobile"
                  />
                </svg>
              </button>
            </picture>

            <div className="thumbnails hidden justify-between h-8 overflow-hidden gap-4 m-auto sm:flex sm:flex-col sm:justify-start sm:items-center sm:h-fit md:gap-5 lg:flex-row">
              {images.slice(0, 4).map((image, index) => (
                <div
                  key={index}
                  onClick={() => setCurrentImage(index)}
                  className={`cursor-pointer rounded-xl overflow-hidden  sm:w-[80px] sm:h-[60px] ${index === currentImage ? 'border-primary  border-2' : ''
                    }`}
                >
{image.slice(0, 5) === "video" && (
                  <IoIosPlayCircle className='absolute text-center justify-center'/>
                  )}
                  <img
                    src={image.slice(0, 5) === "video" ? "https://lh3.googleusercontent.com/d/" + image.slice(5,) + "=s50" : "https://lh3.googleusercontent.com/d/" + image + "=s50"}
                    alt={`thumbnail-${index}`}
                    className="w-full h-full  block object-cover rounded-xl  hover:opacity-50 transition"
                  />
                </div>
              ))}
            </div>

            <div className="thumbnails hidden justify-between h-8 overflow-hidden gap-4 m-auto sm:flex sm:flex-col sm:justify-start sm:items-center sm:h-fit md:gap-5 lg:flex-row">
              {images.slice(4, 8).map((image, index) => (
                <div
                  key={index}
                  onClick={() => setCurrentImage(index + 4)}
                  className={`cursor-pointer rounded-xl overflow-hidden  sm:w-[80px] sm:h-[60px] ${index + 4 === currentImage ? 'border-primary  border-2' : ''
                    }`}
                >
{image.slice(0, 5) === "video" && (
                  <IoIosPlayCircle className='absolute text-center justify-center'/>
                  )}
                  <img
                    src={image.slice(0, 5) === "video" ? "https://lh3.googleusercontent.com/d/" + image.slice(5,) + "=s50" : "https://lh3.googleusercontent.com/d/" + image + "=s50"}
                    alt={`thumbnail-${index}`}
                    className="w-full h-full  block object-cover rounded-xl  hover:opacity-50 transition"
                  />
                </div>
              ))}
            </div>

            <div className="thumbnails hidden justify-between h-8 overflow-hidden gap-4 m-auto sm:flex sm:flex-col sm:justify-start sm:items-center sm:h-fit md:gap-5 lg:flex-row">
              {images.slice(8, 12).map((image, index) => (
                <div
                  key={index}
                  onClick={() => setCurrentImage(index + 8)}
                  className={`cursor-pointer rounded-xl overflow-hidden  sm:w-[80px] sm:h-[60px] ${index + 8 === currentImage ? 'border-primary  border-2' : ''
                    }`}
                >
{image.slice(0, 5) === "video" && (
                  <IoIosPlayCircle className='absolute text-center justify-center'/>
                  )}
                  <img
                    src={image.slice(0, 5) === "video" ? "https://lh3.googleusercontent.com/d/" + image.slice(5,) + "=s50" : "https://lh3.googleusercontent.com/d/" + image + "=s50"}
                    alt={`thumbnail-${index}`}
                    className="w-full h-full  block object-cover rounded-xl  hover:opacity-50 transition"
                  />
                </div>
              ))}
            </div>




            <div
              className="object-cover pt-8 overflow-auto flex-align-center scroll-smooth hide-scrollbar"
            >
              {images.slice(0, 12).map((image, index) => (
                <div
                  onClick={() => setCurrentImage(index)}
                  className={`md:hidden relative flex-shrink-0 cursor-pointer border-2 group overflow-hidden  w-[80px] h-[60px] ${index === currentImage ? 'border-primary ' : 'border-black'}`}
                >
                  
                  {image.slice(0, 5) === "video" && (
                  <IoIosPlayCircle className='absolute text-center justify-center'/>
                  )}


                  <div className="overflow-hidden object-cover">

                    <img
                      src={image.slice(0, 5) === "video" ? "https://lh3.googleusercontent.com/d/" + image.slice(5,) + "=s100" : "https://lh3.googleusercontent.com/d/" + image + "=s100"}
                      className=" w-full h-full object-cover group-hover:scale-125 transition-a"
                    />

                  </div>
   
                </div>
              ))}
            </div>

          </section>





          {/* Text */}
          <section className="w-full p-6 lg:mt-32 lg:pr-20  2xl:pr-40 ">
            <div className="font-bold mb-2 uppercase  tracking-widest text-xs flex items-center space-x-2"> {/* Use 'flex' and 'space-x-2' for horizontal spacing */}
              <p >
                {(Property.Furnishing === "f") ? "Furnished" : ""}
                {(Property.Furnishing === "s") ? "Semi-Furnished" : ""}
                {(Property.Furnishing === "u") ? "UnFurnished" : ""}
              </p>

              {(Property.PropertyType === "PG") ? (<p >PG</p>) : (<p >{Property.BHK} BHK</p>)}
            </div>


            <h1 className="text-very-dark mb-4 capitalize font-bold text-3xl lg:text-4xl">
              {Property.Title}
            </h1>

            <div className=" items-center justify-between mb-6 sm:flex-col sm:items-start">
              <div className="flex items-center gap-4">
                <h3 className="text-very-dark text-primary/80 font-bold text-3xl inline-block">
                  ₹ {Property.Rent}
                </h3>
                <div className=" h-fit py-0.5 px-2 font-bold bg-pale-orange text-orange rounded-lg text-sm">
                  per / month
                </div>
              </div>

              <div className="text-dark-grayish w-fit decoration-dark-grayish decoration-1 my-auto">
                Security Deposite : <b>₹ {Property.Deposite}</b>
              </div>
              {(Property.OWNERLIVES === true) ? <div className=' font-semibold '><p>Owner lives in the same building</p></div> : <div className=' font-semibold'><p>Owner doesn't lives in the same building</p></div>}


            </div>

            <div className="flex justify-between mt-3 py-2  border-y">
              <Link to={"/channel/" + user._id} className="flex-align-center gap-x-2">
                <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
                  <img alt={user.user} src={user.photo} className='rounded-full' />
                </div>
                <p className="text-sm">
                  Posted by {user.user}
                  {user.utype === "b" ? " : Broker" : <></>}
                  {user.utype === "f" ? " : AreaFounder" : <></>}
                  {user.utype === "n" ? " : user" : <></>}

                </p>

              </Link>


            </div>

            <div className="mt-4">
              <div className="text-dark-grayish w-fit decoration-dark-grayish decoration-1 my-auto">
                {Property.desc}
              </div>
            </div>


            <div className=" flex-col gap-5 mb-16 sm:flex-row lg:mb-0 ">




              <div className="flex space-x-5 my-6">
                <a href={"tel:+91" + Property.Phone} className="bg-primary flex-1 text-white px-4 py-2 rounded-md hover:bg-primary/80 flex items-center">
                  <IoCall className='bold mr-2' />
                  Call now
                </a>

                <a href={"https://wa.me/91" + Property.Whatsapp + "?text=Hi, I am interested in your " + Property.PropertyType + "\n " + window.location.href} className="bg-green-500 flex-1 text-white px-4 py-2 rounded-md hover:bg-green-600 flex items-center">
                  <FaWhatsapp className='bold mr-2' />
                  WhatsApp
                </a>
              </div>
              <h1 className="mx-auto sub-heading my-8 ">A m n i t i e s</h1>
              <div className=" overflow-hidden  dark:shadow-primary/40 group">
                <ul className=" flex flex-wrap gap-6">

                  {(Property.Internet === 1) ? <div className='text-primary font-semibold '><p>Internet/Wifi</p></div> : <div className=' font-semibold line-through'><p>Internet/Wifi</p></div>}
                  {(Property.AC === true) ? <div className='text-primary font-semibold '><p>Air Conditioner</p></div> : <div className=' font-semibold line-through'><p>Air Conditioner</p></div>}
                  {(Property.FilterWater === true) ? <div className='text-primary font-semibold '><p>Filtered Water</p></div> : <div className=' font-semibold line-through'><p>Filtered Water</p></div>}
                  {(Property.WahingMachine === true) ? <div className='text-primary font-semibold '><p>Washing Machine</p></div> : <div className=' font-semibold line-through'><p>Washing Machine</p></div>}
                  {(Property.FreeElectricity === true) ? <div className='text-primary font-semibold '><p>200 units Free</p></div> : <div className=' font-semibold line-through'><p> 200 units Free</p></div>}
                  {(Property.CleaningService === true) ? <div className='text-primary font-semibold '><p>Cleaning Services</p></div> : <div className=' font-semibold line-through'><p>Cleaning Services</p></div>}
                  {(Property.GirlsAllowed === true) ? <div className='text-primary font-semibold '><p>Coed</p></div> : <div className=' font-semibold line-through'><p>Coed</p></div>}
                  {(Property.CCTV === true) ? <div className='text-primary font-semibold '><p>CCTV</p></div> : <div className=' font-semibold line-through'><p>CCTV</p></div>}
                  {(Property.Lift === true) ? <div className='text-primary font-semibold '><p>Lift</p></div> : <div className=' font-semibold line-through'><p>Lift</p></div>}
                  {(Property.Parking === true) ? <div className='text-primary font-semibold '><p>Parking</p></div> : <div className=' font-semibold line-through'><p>Parking</p></div>}



                </ul>

              </div>
              {Property.Adds === "Yes" ? <div className="h-full w-full max-w-full">
                <h1 className="mx-auto sub-heading my-8 ">L o c a t i o n</h1>
                <iframe
                  title="Google Map"
                  className="h-full w-full border-0 mt-8"
                  src={"https://www.google.com/maps/embed/v1/place?q=" + Property.loc.coordinates[1] + "," + Property.loc.coordinates[0] + "&key=" + process.env.REACT_APP_GMAP}
                ></iframe>
              </div> : <></>}
            </div>


          </section>

        </main>
      </div>
    </>

  )
}

export default Details
