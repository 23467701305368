import axios from 'axios';

const handleUpload = async (file) => {
  if (!file) {
    console.error('Please select a file to upload');
    return;
  }

  try {
    // Upload the file using FormData
    const accessToken = localStorage.getItem('accesstoken');
    const response = await axios.post(
      'https://www.googleapis.com/upload/drive/v3/files?uploadType=media&fields=id,webViewLink',
      file,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': file.type, // Set the Content-Type based on the file type
        },
      }
    );

    const fileId = response.data.id;

    // Set the permissions to allow anyone with the link to view
    await axios.post(
      `https://www.googleapis.com/drive/v3/files/${fileId}/permissions`,
      {
        role: 'reader',
        type: 'anyone',
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log(response.data.id)
    return response.data.id;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error; // Re-throw the error to handle it outside
  }
};

export default handleUpload;
