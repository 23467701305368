import React from 'react';

function Amenities({ name, handletype }) {
  const handleChange = (event) => {
    // Update the value based on whether the checkbox is checked or not
    const value = event.target.checked ? 1 : 0;

    // Call the handletype function with the updated value
    handletype(value);
  };

  return (
    <li>
      <input
        onChange={handleChange}
        type="checkbox"
        id={name}
        defaultValue=""
        className="hidden peer"
      />

      <label
        htmlFor={name}
        className="inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 bg-transparent	 border-2 border-gray-200  cursor-pointer   dark:border-gray-700 peer-checked:border-primary peer-checked:bg-primary/20  dark:text-gray-400 "
      >
        <div className=" block w-full text-sm font-semibold">{name}</div>
      </label>
    </li>
  );
}

export default Amenities;
