import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { PropertyList } from "../components/property";
import { Pagination } from "../components/property";
import { useParams } from 'react-router-dom';
import { FaWhatsapp } from "react-icons/fa";

function BrokersSite({ property=[], user="sa", handleCallClick, handleWhatsClick, btn="1", id="pra" }) {

    return (
        <>
            {btn === "1" || btn === undefined ?
                <div className='z-40 fixed h-screen w-screen top-0 dark:bg-black bg-white'>


                    <div className="absolute top-0 right-0 rounded-full bg-[#04a7ff]/30 dark:bg-[#04a7ff]/50 w-72 h-72 -z-10 blur-[120px]"></div>
                    <div className="absolute bottom-0 left-0 rounded-full bg-[#04a7ff]/30 dark:bg-[#04a7ff]/50 w-72 h-72 -z-10 blur-[120px]"></div>


                    <div className="home">
                        <Link to="/" className="flex-shrink-0 flex-align-center gap-x-1 px-4 ml-[6%] mt-[8%]">
                            <img className="h-8 w-8 text-primary" src='/Nyapata.png' alt="Nyapata Logo" />
                            <h1 className="text-lg block">NyaPata</h1>
                        </Link>
                        <div className="max-w-screen-xl ml-[6%] px-4 pt-20  ">
                            <div className=" max-w-xl">

                                <Link className="flex items-center space-x-2">
                                    <img
                                        src={user.photo}
                                        alt={user.user}
                                        className="w-20 h-20 m-1 ml-0 mb-3 rounded-lg object-cover"
                                    />
                                </Link>

                                <h1 className="mt-3 font-extrabold text-5xl text-primary sm:block">
                                    {user.Title}
                                </h1>

                                <h1 className="mt-1 font-extrabold text-2xl sm:text-3xl sm:block">
                                    {user.Subt}
                                </h1>

                                <p className="mt-4 sm:text-xl/relaxed">
                                    {user.desc}
                                </p>


                            </div>
                        </div>
                    </div>

                </div>
                : <>
                </>}

            {btn === "2" ?
                <div className='z-40 absolute min-h-screen   w-screen top-0 dark:bg-black bg-white'>

                    <div className="absolute top-0 right-0 rounded-full bg-[#04a7ff]/30 dark:bg-[#04a7ff]/50 w-72 h-72 -z-10 blur-[120px]"></div>
                    <div className="absolute bottom-0 left-0 rounded-full bg-[#04a7ff]/30 dark:bg-[#04a7ff]/50 w-72 h-72 -z-10 blur-[120px]"></div>


                    <div className="md:col-span-5 sm:pt-32  mx-5 md:mt-0 h-fit md:sticky top-0 ">
                        <PropertyList />
                        <Pagination itemsPerPage={10} pageData={property} />
                    </div>

                    <Link to="/" className="flex mx-5 p-2 px-10 mb-20 mt-6 group h-12 border-2 border-gray-300 rounded-full transition duration-300 hover:border-primary-400 ">
                        <span className="w-max font-bold tracking-wide transition duration-300 group-hover:text-primary text-base">View more properties</span>
                    </Link>


                </div>
                : <></>}

            {btn === "3" ?
                <div className='z-40 fixed min-h-screen   w-screen top-0 dark:bg-black bg-white'>

                    <div className="absolute top-0 right-0 rounded-full bg-[#04a7ff]/30 dark:bg-[#04a7ff]/50 w-72 h-72 -z-10 blur-[120px]"></div>
                    <div className="absolute bottom-0 left-0 rounded-full bg-[#04a7ff]/30 dark:bg-[#04a7ff]/50 w-72 h-72 -z-10 blur-[120px]"></div>


                    <div className="flex h-screen flex-col lg:flex-row">


                        {/* Right half for other details */}
                        <div className="lg:w-1/2 sm:h-screen p-8">
                            <div className="max-w-xl mx-auto">

                                <h1 className="mt-3 font-extrabold text-5xl text-primary sm:block">
                                    {user.Title}
                                </h1>

                                <h1 className="mt-1 font-extrabold text-2xl sm:text-3xl sm:block">
                                    {user.Subt}
                                </h1>

                                <a onClick={handleCallClick} className="flex w-fit mt-6 group h-12 px-6 border-2 border-gray-300 rounded-full transition duration-300 hover:border-primary-400 focus:bg-blue-50 active:bg-blue-100">
                                    <div className="relative flex items-center space-x-4 justify-center">
                                        <span className="block w-max font-semibold tracking-wide text-sm transition duration-300 group-hover:text-primary sm:text-base">Call</span>
                                    </div>
                                </a>

                                <button onClick={handleWhatsClick} className="flex w-fit mt-6 group h-12 px-6 border-2 border-gray-300 rounded-full transition duration-300 hover:border-primary-400 focus:bg-blue-50 active:bg-blue-100">
                                    <div className="relative flex items-center space-x-4 justify-center">
                                        <span className="w-max font-semibold tracking-wide text-sm transition duration-300 group-hover:text-primary sm:text-base">Whatsapp</span>
                                    </div>
                                </button>

                                <p className="mt-4 sm:text-xl/relaxed">
                                    Address : {user.Address}
                                </p>

                            </div>
                            {/* Left half for Google Map */}

                        </div>

                        <div className="lg:w-1/2 hidden md:block">
                            <iframe
                                title="Google Map"
                                className="h-screen w-full lg:h-full border-0"
                                src={`https://www.google.com/maps/embed/v1/place?q=${0},${0}&key=${process.env.REACT_APP_GMAP}`}
                            ></iframe>
                        </div>
                        <div className="md:hidden block">
                            <iframe
                                title="Google Map"
                                className="h-full w-full border-0 "
                                src={"https://www.google.com/maps/embed/v1/place?q=" + user.loc.coordinates[1] + "," + user.loc.coordinates[0] + "&key=" + process.env.REACT_APP_GMAP}
                            ></iframe>
                        </div>


                    </div>


                </div>
                : <></>}


            <div className="footer">
                <div className="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-900 dark:border-gray-600">
                    <div className="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
                        <NavLink
                            to={"/Channel/" + id + "/1"}
                            type="button"
                            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
                        >
                            <svg
                                className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-primary/80 dark:group-hover:text-primary/80"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                            >
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                            </svg>
                            <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-primary/80 dark:group-hover:text-primary/80">
                                Home
                            </span>
                        </NavLink>
                        <Link
                            to={"/Channel/" + id + "/2"}
                            type="button"
                            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
                        >
                            <svg
                                className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-primary/80 dark:group-hover:text-primary/80"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                            >
                                <path d="M16 17h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h4"></path>
                                <polyline points="12 2 12 10 16 10"></polyline>

                            </svg>
                            <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-primary/80 dark:group-hover:text-primary/80">
                                Properties
                            </span>
                        </Link>

                        <Link
                            type="button"
                            to={"/Channel/" + id + "/3"}
                            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
                        >
                            <svg
                                className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-primary/80 dark:group-hover:text-primary/80"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                            >
                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                            </svg>
                            <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-primary/80 dark:group-hover:text-primary/80">
                                Location
                            </span>
                        </Link>
                        <button
                            onClick={handleWhatsClick}
                            type="button"
                            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
                        >
                            <FaWhatsapp 
                                className="w-5 h-5 mb-2 text-gray-500 dark:text-gray-400 group-hover:text-primary/80 dark:group-hover:text-primary/80"
                                />

                            <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-primary/80 dark:group-hover:text-primary/80">
                                WhatsApp
                            </span>
                        </button>

                    </div>
                </div>
            </div>
        </>

    )
}


//  <div className="md:col-span-5 mt-5 md:mt-0 h-fit md:sticky top-0 ">
//                     <PropertyList />
//                     <Pagination itemsPerPage={10} pageData={property} />
//                 </div> 
export default BrokersSite
