
import Pages from "./Pagesroute";
import "./app.css";

function App() {

  return (
    <Pages />
  );
}

export default App;
