import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Footerh/Navbar";
import Footerh from "./components/Footerh/Footerh";

import Home from "./Pagess/Home";
import Property from "./Pagess/Property";
import Broker from "./Pagess/Brokers";
import JoinUsForm from "./Pagess/JoinUs";
import Blog from "./Pagess/Blog";
import Faqs from "./Pagess/faqs";
import About from "./Pagess/About";
import PageNotFound from "./Pagess/PageNotFound";

import Upload from "./Pagess/Upload";
import Login from "./components/Footerh/Login";
import Details from "./components/Details/Details";

import ProfilePage from "./Pagess/ProfilePage";
import OwnProperties from "./components/Property Control/OwnProperties/OwnProperties";
import SubAdmin from "./components/Property Control/SubAdmin/SubAdmin"
import FinalDelte from "./components/Property Control/FinalDelete/FinalDelete";
import AboutU from "./Pagess/About";
import GoogleAuth from "./components/Footerh/GoogleAuth";
import Channel from "./Pagess/Channel";
import Singleblog from "./Pagess/Singleblog";
import { useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";
import Terms from "./components/Footerh/Terms";
import Privacy from "./components/Footerh/Privacy";

function Pages() {
  
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <div>
      {/* { (Request.path=="/singup") ? null : <Navbar /> } */}
      <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/property/:PropertyType/:lat/:lng" element={<Property />} />
          <Route path="/details/:Propertyid/:Propertyname" element={<Details />} />

          <Route path="/about-us" element={<About />} />
          <Route path="/blog"    element={<Blog />} />
          <Route path="/login" element={<Login />} />

          <Route path="/upload" element={<Upload />} />

          <Route path="/faqs" element={<Faqs />} />
          <Route path="/home" element={<Home />} />

          <Route path="/ProfilePage" element={<ProfilePage />} />
          <Route path="/OwnProperties/:id/:name" element={<OwnProperties />} />
          <Route path="/SubAdmin" element={<SubAdmin />} />
          <Route path="/FinalDelte" element={<FinalDelte />} />
          <Route path="/Broker" element={<Broker />} />
          <Route path="/GoogleAuth" element={<GoogleAuth />} />


          <Route path="/JoinUs" element={<JoinUsForm />} />
          <Route path="/AboutUs" element={<AboutU />} />

          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />


          <Route path="/Channel/:id/:btn" element={<Channel />} />
          <Route path="/Channel/:id" element={<Channel />} />
          <Route path="/Blogpage/:id" element={<Singleblog />} />
          {/* <Route path="/login" element={<LoginPage />} /> */}


          <Route path="*" element={<PageNotFound />} />

           

        </Routes>

      <Footerh/>
    </div>
  );
}

export default Pages;
