import TestimonialCard from "./TestimonialCard";
import { useState,useEffect } from "react";
import { request } from "../../features/featchApi";
const Testimoniall = () => {
  const [testimonials,setTestimonials]= useState([])

  useEffect(()=>{
    
    const fetchhome = async() => {
     try {
      const data = await request('/AboutTestimonials', 'GET')
      setTestimonials(data[0].testimonials)
       
     } catch (error) {
       console.error(error.message)
     }
    }
    fetchhome();
}, [])
  return (
    <div className="pt-10 pb-16">
      <div className="text-center">
        <h1 className="mx-auto sub-heading">testimonial</h1>
        <h1 className="heading">what they're saying about our work feedback</h1>
      </div>
      <div className="flex flex-wrap gap-4 mt-8">
        {testimonials.map((testimonial) => (
          <TestimonialCard {...testimonial} key={testimonial.id} />
        ))}
      </div>
    </div>
  );
};

export default Testimoniall;