import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    user: null,
    token: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action) {
            localStorage.clear()
            state.user = action.payload.newuser
            state.token = action.payload.token
        },
        setaccess(dataa) {
            localStorage.clear()
            localStorage.setItem("accesstoken",JSON.stringify(dataa))
        },
        logout()   {
            localStorage.clear()
        }
    },
})

export const { login, register, logout } = authSlice.actions

export default authSlice.reducer    