import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

const NewsLetter = () => {
  return (
    <div className="flex flex-wrap -mt-24  bg-primary h-fit sm:h-[250px] rounded-xl py-4 text-slate-100">
      <div className="flex-1 basis-[20rem] md:mx-5 ">
        <img
          src="https://lh3.googleusercontent.com/pw/ABLVV84zsBrBoC1LYHo0MyOdANfnretmYaRM_y9ARfNaS2KaS9obBb9pn7W09QTn5YdYSgfkNVEDRKW4Hx0hOrDE8N7MkBSbSQKTdIjenX_1dnC0Jd7C9tw=s400"
          alt="Post Property and Earn from it Nyapata"
          className="w-[300px] lg:w-[300px] -mt-12 mx-8"
        />
      </div>
      <div className="flex-1 basis-[16rem] md:basis-[30rem] mt-3 sm:mt-10">
        <div className="text-center sm:text-left">
          <h1 className="text-2xl font-bold capitalize">
          List properties of others and earn your commission
          </h1>
          <a>
            Click here to learn more
          </a>
        </div>
        <div className="mt-3">
        <Link to="/upload">
          <div className="justify-center flex-align-center gap-x-2 sm:justify-start">

            
            <button  className=" flex p-2 rounded-md btn-secondary">
              <Link to="/upload" className="mr-2  ">Post Property for free</Link>
              <Link to="/upload" className="p-1"><FiArrowRight />  </Link>
            </button>
            
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
