import Amenities from "./Amenities";
import Profile from "./Profile";
import { useState, useEffect } from "react";
import xss from 'xss';
import ImageUploader from "./ImageUpload";
import AdressUpload from "./AdressUpload";
import axios from "axios";
import Process from "./Process";
import { request } from "../../features/featchApi";
import { useNavigate } from "react-router-dom";


const Propertyup = () => {
  useEffect(() => {
    if (localStorage.getItem('tokenExpiryTime') < Date.now())
      {
        localStorage.removeItem('token');
      localStorage.removeItem('accesstoken');
      localStorage.removeItem('tokenExpiryTime');}
    });
  const [property, setProperty] = useState({});
  const [files, setFiles] = useState([]);
  const [btn, setbtn] = useState(0);
  const [count,setcount] = useState(1);
  const handlestate = (e) => {
    setProperty({ ...property, [e.target.name]: xss(e.target.value) });
  };

  const handleType = (feature, type) => {
    setProperty(prevProperty => ({
      ...prevProperty,
      [feature]: type
    }));  };

  const [verify,setVerify] = useState()
  const handlesubmit = async(e) => {
    setbtn(1)
    const verifying = () => {
      const verify=verifyproperty()
      if (verify !== 1) {
        setVerify(verify)
        throw new Error(verify);
      };
    }

    verifying();
    
    setbtn(2);
    await handleUpload();
    setbtn(3);
    const newp = await Createprofileback();
    if (newp.currentOwner){
      setbtn(4);
    }
    else (console.log(newp))


  };
  const navigate = useNavigate();

  useEffect(() => {
    // Check if token is available in localStorage
    const token = localStorage.getItem('token');
  
    if (!token) {
      navigate('/login'); // Change '/login' to your login page route
    }
  
  });

  const verifyproperty= () => {
    if (files.length === 0) {
      return "Please Select Images/Videos of property.";
    }

    if (!["PG", "Flat"].includes(property.PropertyType)) {
      return "Please Select Property Type Type Flat or PG.";
    }

    if (!["f", "s", "u"].includes(property.Furnishing)) {
      return "Please Select Furnishing Type.";
    }

    if (![0, 1, 2, 3, 4, 5].includes(property.BHK)) {
      return("Please Select BHK.");
    }

    // if (property.OWNERLIVES!==1 || property.OWNERLIVES!==0 )
    if (![0, 1].includes(property.OWNERLIVES)) {
      return "Please Select Owner lives in the same building ?";
    }

    if (!property.Title || property.Title.length < 8 || property.Title.length > 40) {
      return("Invalid Title. It should be a string with length between 8 and 40 characters.");
    }

    if (property.desc) {
      if (property.desc.length > 400) {
        return ("Invalid Description. It should be a string with a maximum length of 400 characters.");
      }
    }
    

    if (property.Rent < 1500 || property.Rent > 200000) {
      return("Invalid Rent. It should be a number between 1500 and 200000.");
    }

    if (property.Deposite) {
      if(property.Deposite > 200000){ 
      return("Invalid Deposite. It should be a less than 200000.");}
    }

    if (!["Yes", "No"].includes(property.Adds)) {
      return "Please Select Do you want the address to be shown to the user?";
    }

    if (property.loc.coordinates[0]=== 77.11700006703794) {
      return "Please Select Pin drop location from map";
    }

    if (!property.Addone || property.Addone.length < 3 || property.Addone.length > 20) {
      return("Invalid Adressline one. It should be a string with length between 3 and 20 characters.");
    }

    if (!property.Addtwo || property.Addtwo.length < 3 || property.Addtwo.length > 20) {
      return("Invalid AdressTwo one. It should be a string with length between 3 and 20 characters.");
    }

    if (!property.Phone || property.Phone.length !== 10  ) {
      return("Invalid Phone number");
    }

    if (!property.Whatsapp || property.Phone.length !== 10 ) {
      return("Invalid Whatsapp number");
    }
    return 1;
    
  }

  const Createprofileback = async () => {
    try {
      const token = localStorage.getItem('token');
      const header ={
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
      

      const data = await request("/create", 'POST',header,property)
      return data;
    } catch (error) {
      console.error(error.message)
    }
  }


  const handleUpload = async () => {
    const uploadedUrls = [];
    for (const file of files) {
      if (!file) {
        console.error('Please select a file to upload');
        return;
      }
      try {
        // Upload the file using FormData
        const accessToken = localStorage.getItem('accesstoken');
        const response = await axios.post(
          'https://www.googleapis.com/upload/drive/v3/files?uploadType=media&fields=id,webViewLink',
          file,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': file.type, // Set the Content-Type based on the file type
            },
          }
        );
  
        const fileId = response.data.id;

        if (file.type.startsWith('video/')) {
          const string = "video" + response.data.id
          uploadedUrls.push(string);
        }else{uploadedUrls.push(response.data.id);}

        setcount(count+1) // Add the Google URL to the array
  
        // Set the permissions to allow anyone with the link to view
        await axios.post(
          `https://www.googleapis.com/drive/v3/files/${fileId}/permissions`,
          {
            role: 'reader',
            type: 'anyone',
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }
        );

      } catch (error) {
        console.error('Error uploading file:', error);
        throw error; // Re-throw the error to handle it outside
      }
    }
  
    property.images = uploadedUrls;
  };

  return (
    <div className="h-fit mt-8 max-h-full">

      {btn!== 0 ? < Process btn={btn} setbtn={setbtn} verify={verify} setVerify={setVerify} count={count}/> : <></>}
      
      <div className="sm:w-1/2 pt-3 m-auto  dark:shadow-primary/40">
        
        <div className="dark:shadow-primary/40 sm:col-span-2 md:col-span-3 mx-2 border border-gray-200 rounded-lg shadow dark:border-gray-700 p-3 gap-3 mt-3 overflow-hidden">

        <ImageUploader files={files} setFiles={setFiles}/>

        </div>

        <div className="dark:shadow-primary/40 sm:col-span-2 md:col-span-3 mx-2 border border-gray-200 rounded-lg shadow dark:border-gray-700 p-3 gap-3 mt-3 overflow-hidden">
            
            <div className="mb-4 mt-4">
              <label className="block text-gray-700 dark:text-gray-400 text-sm font-bold mb-2">
                Property Type *
              </label>
              <div className="flex space-x-2">

 
                <button
                  type="button"
                  onClick={() => {handleType("PropertyType", "Flat"); handleType("BHK", null)}}
                  className={`inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 border-2 border-gray-200 cursor-pointer dark:text-gray-400 
                  ${property.PropertyType === 'Flat' && property.BHK !== 0 ? 'border-primary bg-primary/20' : 'dark:border-gray-700 bg-transparent'}`}
                >
                  <div className=" block w-full text-sm font-semibold tracking-widest"> Flat </div>
                </button>

                <button
                  type="button"
                  onClick={() => {handleType("PropertyType", "PG"); handleType("Furnishing", "f"); handleType("BHK", 1); }}
                  className={`inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 border-2 border-gray-200 cursor-pointer dark:text-gray-400 
                  ${property.PropertyType === 'PG' ? 'border-primary bg-primary/20' : 'dark:border-gray-700 bg-transparent'}`}
                >
                  <div className=" block w-full text-sm font-semibold tracking-widest"> PG </div>
                </button>

                <button
                  type="button"
                  onClick={() => {handleType("PropertyType", "Flat"); handleType("BHK", 0);}}
                  className={`inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 border-2 border-gray-200 cursor-pointer dark:text-gray-400 
                  ${property.PropertyType === 'Flat' && property.BHK === 0 ? 'border-primary bg-primary/20' : 'dark:border-gray-700 bg-transparent'}`}
                >
                  <div className=" block w-full text-sm font-semibold tracking-widest"> Room </div>
                </button>
            </div>
            </div>  
            
            {/* Furnished */}
            {property.PropertyType === 'Flat'  ? 
            <>
            <div className="mb-4 mt-4">
              <label className="block text-gray-700 dark:text-gray-400 text-sm font-bold mb-2">
                Furnishing Type *
              </label>
              <div className="mr-2  ">
                <button
                  type="button"
                  onClick={() => handleType("Furnishing", "f")}
                  className={`mr-2 mb-2 inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 border-2 border-gray-200 cursor-pointer dark:text-gray-400 ${property.Furnishing === 'f' ? 'border-primary bg-primary/20' : 'dark:border-gray-700 bg-transparent'}`}
                >
                  <div className=" block w-full text-sm font-semibold tracking-widest"> Furnished </div>
                </button>
                <button
                  type="button"
                  onClick={() => handleType("Furnishing", "s")}
                  className={`mr-2 mb-2 inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 border-2 border-gray-200 cursor-pointer dark:text-gray-400 ${property.Furnishing === 's' ? 'border-primary bg-primary/20' : 'dark:border-gray-700 bg-transparent'}`}
                >
                  <div className=" block w-full text-sm font-semibold tracking-widest"> Semi-Furnished </div>
                </button>
                <button
                  type="button"
                  onClick={() => handleType("Furnishing", "u")}
                  className={`mr-2 mb-2 inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 border-2 border-gray-200 cursor-pointer dark:text-gray-400 ${property.Furnishing === 'u' ? 'border-primary bg-primary/20' : 'dark:border-gray-700 bg-transparent'}`}
                >
                  <div className=" block w-full text-sm font-semibold tracking-widest"> Un-Furnished </div>
                </button>
              </div>
            </div>

            {property.BHK === 0 ? <></>:<div className="mb-4 mt-4">
              <label className="block text-gray-700 dark:text-gray-400 text-sm font-bold mb-2">
                BHK *
              </label>
              <div className="mr-2  ">

                <button
                  type="button"
                  onClick={() => handleType("BHK", 1 )}
                  className={`mr-2 mb-2 inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 border-2 border-gray-200 cursor-pointer dark:text-gray-400 ${property.BHK === 1 ? 'border-primary bg-primary/20' : 'dark:border-gray-700 bg-transparent'}`}
                >
                  <div className=" block w-full text-sm font-semibold tracking-widest"> 1 BHK </div>
                </button>
                <button
                  type="button"
                  onClick={() => handleType("BHK", 2)}
                  className={`mr-2 mb-2 inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 border-2 border-gray-200 cursor-pointer dark:text-gray-400 ${property.BHK === 2 ? 'border-primary bg-primary/20' : 'dark:border-gray-700 bg-transparent'}`}
                >
                  <div className=" block w-full text-sm font-semibold tracking-widest"> 2 BHK </div>
                </button>
                <button
                  type="button"
                  onClick={() => handleType("BHK", 3)}
                  className={`mr-2 mb-2 inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 border-2 border-gray-200 cursor-pointer dark:text-gray-400 ${property.BHK === 3 ? 'border-primary bg-primary/20' : 'dark:border-gray-700 bg-transparent'}`}
                >
                  <div className=" block w-full text-sm font-semibold tracking-widest"> 3 BHK </div>
                </button>
                <button
                  type="button"
                  onClick={() => handleType("BHK", 4)}
                  className={`mr-2 mb-2 inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 border-2 border-gray-200 cursor-pointer dark:text-gray-400 ${property.BHK === 4 ? 'border-primary bg-primary/20' : 'dark:border-gray-700 bg-transparent'}`}
                >
                  <div className=" block w-full text-sm font-semibold tracking-widest"> 4 BHK </div>
                </button>
                <button
                  type="button"
                  onClick={() => handleType("BHK", 5)}
                  className={`mr-2 mb-2 inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 border-2 border-gray-200 cursor-pointer dark:text-gray-400 ${property.BHK === 5 ? 'border-primary bg-primary/20' : 'dark:border-gray-700 bg-transparent'}`}
                >
                  <div className=" block w-full text-sm font-semibold tracking-widest"> 4+ BHK </div>
                </button>
              </div>
            </div>}
            

            </> : <>
            </>  }

            {property.PropertyType ? 
            <>
                        <div className="mb-4 mt-4">
              <label className="tracking-widest block text-gray-700 dark:text-gray-400 text-sm font-bold mb-2">
                Owner lives in the same building ? *
              </label>
              <div className="flex space-x-2">

 
                <button
                  type="button"
                  onClick={() => handleType("OWNERLIVES", 1)}
                  className={`inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 border-2 border-gray-200 cursor-pointer dark:text-gray-400 ${property.OWNERLIVES === 1 ? 'border-primary bg-primary/20' : 'dark:border-gray-700 bg-transparent'}`}
                >
                  <div className=" block w-full text-sm font-semibold tracking-widest"> Yes </div>
                </button>

                <button
                  type="button"
                  onClick={() => handleType("OWNERLIVES", 0)}
                  className={`inline-flex items-center rounded-full text-center justify-between w-fit pr-2 pl-2 pb-1 pt-1 text-gray-900 border-2 border-gray-200 cursor-pointer dark:text-gray-400 ${property.OWNERLIVES === 0 ? 'border-primary bg-primary/20' : 'dark:border-gray-700 bg-transparent'}`}
                >
                  <div className=" block w-full text-sm font-semibold tracking-widest"> No </div>
                </button>

            </div>
            </div> 
            </>: <>

            </>  }
            



              

 

            

            
        </div>

        <div className="dark:shadow-primary/40 mx-2 border border-gray-200 rounded-lg shadow dark:border-gray-700 p-3 gap-3 mt-3 overflow-hidden">
          <div className="sm:col-span-2 md:col-span-3 p-3">
            {/* input title */}
            <div className="mb-4">
              <h1 className="text-lg font-bold capitalize ">
                <label htmlFor="propertyType" id="Title" className="block dark:text-gray-400 text-sm font-bold mb-2">
                  Title
                </label>
                <input name="Title" maxLength="40"
                onInput={(e) => e.target.validity.valid || (e.target.value = (e.target.value).slice(0,40))}  type="Text"  onChange={handlestate} className="w-full p-3 h-11 bg-transparent rounded border border-gray-200 focus:outline-none focus:border-primary/40" />
              </h1>
            </div>
            {/* description */}
            <label className="block text-gray-700 dark:text-gray-400 text-sm font-bold mb-2">
              Property Desc *
            </label>
            <textarea name="desc" maxLength="400"
                onInput={(e) => e.target.validity.valid || (e.target.value = (e.target.value).slice(0,400))}  onChange={handlestate} placeholder="Enter few words about property" className="bg-transparent p-3 w-full h-16  text-white-700 placeholder-white-100 border focus:border-primary/40 focus:outline-none"></textarea>
  
            {/* price */}
            <div>
              <div className="mt-5 text-lg border-primary/80 border-2 font-bold capitalize overflow-hidden sm:cursor-pointer flex-align-center w-full">
                <div className="h-10 py-2 w-10 bg-primary/80 text-center">
                  <span className="text-white ">₹</span>
                </div>
                <input name="Rent" min="0" onInput={(e) => e.target.validity.valid || (e.target.value = '')} onChange={handlestate} className="pl-1 h-10 p-1 bg-transparent w-full focus:outline-none" type="Number" placeholder="Rent " aria-label="Full name" />
              </div>

                <div className="mt-5 text-lg border-primary/20 border-2 font-bold capitalize overflow-hidden sm:cursor-pointer flex-align-center  ">
                  <div className="h-10 py-2 w-10 bg-primary/20 text-center">
                    <span className="text-white ">₹</span>
                  </div>
                  <input min="0" onInput={(e) => e.target.validity.valid || (e.target.value = '')} name="Deposite" onChange={handlestate} className="pl-1 h-10 p-1 bg-transparent w-full focus:outline-none" type="Number" placeholder="Deposite " aria-label="Full name" />
                </div>
      
              </div>


          </div>
        </div>

        <div className="dark:shadow-primary/40 mx-2 border border-gray-200 rounded-lg shadow dark:border-gray-700 p-3 gap-3 mt-3 overflow-hidden group">
          <div className="sm:col-span-2 md:col-span-3 p-3">
            <ul className="flex flex-wrap gap-6 p-3">
              <Amenities handletype = {(value) => handleType("Internet", value )} name="Internet/Wifi" />
              <Amenities handletype = {(value) => handleType("FilterWater", value )} name="Filtred Water" />
              <Amenities handletype = {(value) => handleType("AC", value )} name="AC" />
              <Amenities handletype = {(value) => handleType("WahingMachine", value )} name="Washing Machine" />
              <Amenities handletype = {(value) => handleType("CleaningService", value )} name="Cleaning Services" />
              <Amenities handletype = {(value) => handleType("CCTV", value )} name="CCTV " />
              <Amenities handletype = {(value) => handleType("Lift", value )} name="Lift " />
              <Amenities handletype = {(value) => handleType("Parking", value )} name="Parking " />
              <Amenities handletype = {(value) => handleType("FreeElectricity", value )} name="Free Electricity " />
              <Amenities handletype = {(value) => handleType("GirlsAllowed", value )} name="Girls Allowed " />
            </ul>
          </div>
        </div>

        <div className="dark:shadow-primary/40 bg-transparent h-fit mx-2 border overflow-hidden border-gray-200 rounded-lg shadow dark:border-gray-700 p-3 gap-3 mt-3 group">
        <AdressUpload property={property} handleType={(name,value)=> handleType(name,value)} setProperty={setProperty} handlestate={handlestate}/> 

        </div>

        <div className="dark:shadow-primary/40 mx-2 border border-gray-200 rounded-lg shadow dark:border-gray-700 p-3 gap-3 mt-3 overflow-hidden group">
          <Profile property={property} handlestate={handlestate} handleType={handleType} />
        </div>


        
        <button onClick={handlesubmit} className="w-full   px-4 my-2 py-3 text-lg font-semibold text-white transition-colors duration-300 bg-primary/75 rounded-md shadow hover:bg-primary focus:outline-none focus:ring-blue-200 focus:ring-4">
          {btn===0 ? <>Post Property</> : <></>}
          {btn===1 ? <>Verifying Data</> : <></>}
          {btn===1 ? <>Uploading Images</> : <></>}
          {btn===3 ? <>POSTED </> : <></>}
        </button>



      </div>
    </div>
  );
};

export default Propertyup;
