import { Link } from "react-router-dom";
import { request } from "../../../features/featchApi";
import { useState } from "react";


const SingleProductCardFullWidth = ({
  propertyy,
}) => {
  
  const key = propertyy._id
  const [dlttext,setdlttext]=useState("Delete Parmanent")
  const [vertext,setvertext]=useState("Verify Property")

  const dltpropertyback = async () => {
    try {
      const token = localStorage.getItem('token');

      const header ={
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
      setdlttext("Deleted")
      await request("/"+ key, 'DELETE',header)
    } catch (error) { 
      console.error(error.message)
    }
  }

  const verifypropback = async () => {
    try {
      const token = localStorage.getItem('token');

      const header ={
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
      setvertext("Verified")
      await request("/verify/"+ key, 'PUT',header)
    } catch (error) { 
      console.error(error.message)
    }
  }
  return (
    <div className="relative grid gap-3 mb-6 mt-3 overflow-hidden border rounded-lg shadow-light grid-cols-3 md:grid-cols-4 dark:border-card-dark group">
      <div className="col-span-1">
        <div className="group !opacity-100 overflow-hidden relative h-[240px] ">
          <Link to={"/details/"+ key + "/"+ propertyy.Title}  className="!opacity-100">
            <img
              src={"https://lh3.googleusercontent.com/d/"+ propertyy.images[0]}
              alt={propertyy.Title}
              className="object-cover w-full h-full group-hover:scale-125 transition-a"
            />
          </Link>
        </div>
      </div>
      <div className="col-span-2 md:col-span-3">
        <div className="p-3">
          <Link to={"/details/"+ key + "/"+ propertyy.Title} className="group-hover:text-primary transition-a">
            <h1 className="text-lg font-bold capitalize">{propertyy.Title}</h1>
          </Link>
          <div className="mt-4  flex-center-between">
            <h1 className="text-lg font-semibold text-primary">₹ {propertyy.Rent}</h1>
          </div>
        </div>

        <div className="mt-4 w-full flex-container flex-row space-x-2 space-y-2">
            <button onClick={dltpropertyback} className="btn btn-secondary">{dlttext}</button>
            <button onClick={verifypropback} className="btn btn-secondary">{vertext}</button>
            <button className="btn btn-secondary">Edit  Property!</button>
      </div>
      </div>
    </div>
  );
};

export default SingleProductCardFullWidth;
