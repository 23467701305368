/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { FiDelete, FiMoon, FiSun } from "react-icons/fi";
import { BiMenu, BiUser } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";

import Login from "./Login";
import Singup from "./Singup";

import {
  closeSidebar,
  openSidebar,
  toggleDarkMode,
  uiStore,
} from "../../features/uiSlice";
import {navLinks} from "../../data/navLinks";
import SingleLink from "./SingleLink";

const Navbar = () => {
  const dispatch = useDispatch();
  const rootDoc = document.querySelector(":root");
  const { darkMode, isSidebarOpen } = useSelector(uiStore);
  



  // Dark mode toggle
  const handleDarkMode = () => {
    dispatch(toggleDarkMode());
  };

  // Store darkmode value to localStorage;
  useEffect(() => {
    if (darkMode) rootDoc.classList.add("dark");
    else rootDoc.classList.remove("dark");
  }, [darkMode]);



  const handleCloseSidebar = (e) => {
    if (e.target.classList.contains("mobile-modal")) dispatch(closeSidebar());
  };

  const [seen, setSeen] = useState(false)

  function togglePop () {
        setLoginseen(false)
        setSeen(!seen);
    };

  const [loginseen, setLoginseen] = useState(false)

  function togglePoplogin () {
          setSeen(false);
          setLoginseen(!loginseen)
      };

  return (
    <>
    <div className="md:hidden  h-[15px] fixed w-full z-40 top-0  bg-primary/60  backdrop-blur-sm">
    <Link to={"/Blogpage/earn_by_posting"} className=" text-xs w-full uppercase text-white ">
    <marquee>List properties of others and earn 500 per property. Click here</marquee>      
    </Link>
    
    </div>
    <div className="navbar h-[45px] fixed w-full z-20 top-[15px] md:top-0 left-0 px-[2%]  md:px-[6%] flex-center-between py-[0.35rem] bg-white/60 border-b backdrop-blur-sm dark:border-dark dark:bg-card-dark/60">
      
      <Link to="/" className="flex-shrink-0 flex-align-center gap-x-1 no-underline">
        <img className="h-8 w-8 text-primary" src='/Nyapata.png' alt="Nyapata Logo" />
        <h1 className="hidden md:block">NyaPata</h1>
      </Link>

      <div className="flex-align-center gap-x-4">

        {/*-------------------------------------- Desktop Menu------------------------------------- */}
        <ul
          className={`hidden md:flex-align-center`}
        >
          {navLinks.map((link) => (
            <SingleLink {...link} key={link.id} />
          ))}
        </ul>

        {/*---------------------------------------- Mobile Menu------------------------------------- */}
        <div
          className={`lg:hidden mobile-modal fixed w-screen h-screen top-0 left-0 bg-black/50 z-50 opacity-0 pointer-events-none transition-a  ${isSidebarOpen && "open"
            }`}
          onClick={handleCloseSidebar}
        >
          <ul
            className={`mobile-dialog overflow-auto absolute flex flex-col space-y-4 p-3 bg-white dark:bg-card-dark h-screen max-w-[300px] w-full -translate-x-[500px] transition-a ${isSidebarOpen && "open"
              }`}
          >
            <div className="border-b flex-center-between dark:border-slate-800">
              <p className="uppercase">menu</p>
              <div
                className="icon-box md:hidden"
                onClick={() => dispatch(closeSidebar())}
              >
                <FiDelete/>
              </div>
            </div>
            {navLinks?.map(({ id, linkText, url, subLinks }) => (
              <ul key={id}>
                <NavLink
                  to={url}
                  end
                  className="w-fit before:!hidden"
                  onClick={() => dispatch(closeSidebar())}
                >
                  {linkText}
                </NavLink>

              </ul>
            ))}
          </ul>
        </div>

        <div className="space-x-2 flex-align-center">
          {/*----------------------------- Upload Bar----------------------------------------------------- */}
          <Link to="/upload" >
          <span  className="hidden md:block py-[3px] px-2 text-sm rounded-full capitalize text-white bg-primary/80">
         Post Property <span className="py-[3px] px-3 text-sm rounded-full capitalize text-white bg-secondary/80">
         Free 
      </span>
     
      </span>
      </Link>
      

          {/*----------------------------- Dark mode toggle-------------------------------------------------- */}
          <div
            className="bg-white shadow-md icon-box dark:bg-dark-light hover:shadow-lg hover:bg-transparent"
            onClick={handleDarkMode}
          >
            {darkMode ? <FiSun /> : <FiMoon />}
          </div>

            {/* Profile Icon-------------------------------------------------- */}
          <Link to="/ProfilePage" className="bg-white shadow-md icon-box dark:bg-dark-light hover:shadow-lg hover:bg-transparent">
            <BiUser />
          </Link> 

          
          


          {/*------------------------------- Mobile Menu Toogle------------------------- */}
          <div
            className="icon-box md:hidden"
            onClick={() => dispatch(openSidebar())}
          >
            <BiMenu />
          </div>
        </div>
      </div>
    </div>

    
    
    {seen ? <Singup toggle={togglePop} login={togglePoplogin}/> : null}
    {loginseen ? <Login toggle={togglePoplogin} login={togglePop}/> : null}
  

    </>

  );
};

export default Navbar;
