
import { NavLink } from "react-router-dom";

const SingleLink = ({ id, linkText, url }) => {

  return (
    <div className="relative">
      <NavLink
        to={url}
        end
        key={id}
        className="relative w-full px-3 py-[0.6rem] lg:px-4 flex-align-center gap-x-1 link"
      >
        {linkText}
      </NavLink>
    </div>
  );
};

export default SingleLink;
