import React from "react";
import { FiFilter } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { openFilterMenu } from "../../../features/uiSlice";

const HeadeFilters = () => {
  const dispatch = useDispatch();
  return (
    <div className="flex-col gap-4 flex-center-between md:flex-row">
      
      <div className="w-full gap-4 flex-center-between">
        {/* <select
          name=""
          id=""
          className="w-1/4 px-3 py-2 bg-white border outline-none dark:border-dark dark:bg-main-dark"
        >
          <option value="">Sort by</option>
          <option value="latest">Latest</option>
          <option value="cheapest">Cheapest</option>
          <option value="expensive">Expensive</option>
        </select> */}

        <div
            className="grid w-10 h-10 md:hidden rounded-xl place-items-center bg-slate-100 sm:cursor-pointer hover:bg-slate-200 transition-a dark:bg-card-dark"
            onClick={() => dispatch(openFilterMenu())}
          > 
            <FiFilter />
          </div>

      </div>
      
    </div>
  );
};

export default HeadeFilters;
