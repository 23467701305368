// import { feeds } from "../data/dummyData";
import BlogPostsList from "../components/blog/BlogPostsList";
import { Pagination } from "../components/property";
import { useEffect, useState } from "react";
import { request } from "../features/featchApi";

const Blog = () => {
  const [feeds,setfeed] = useState([])
  const ReciveBlogBack = async () => {
    try {
      const data = await request("/blog", 'GET')
      setfeed(data);
    } catch (error) { 
      console.error(error.message)
    }
  }

  useEffect(()=>{
    ReciveBlogBack()
  },[])

  return (
    <div className="pt-20 px-[3%] md:px-[6%]">
      <BlogPostsList />
      <Pagination itemsPerPage={6} pageData={feeds} />
    </div>
  );
};

export default Blog;
