import React, { useState } from 'react';
import GoogleLoginComponent from './GoogleAuthh';
import handleUpload from './handleUpload';
import DraggablePointMap from '../Upload/DraggablePointMap';

const UploadImageToDrive = () => {

  const [coordinates, setCoordinates] = useState({
    longitude: 77.11700006703794,
    latitude: 28.745090001507602,
  });

  const [file, setFile] = useState(null);
  const videoSrc = "https://drive.google.com/uc?id=1AlQaVKeJUThDR6oYVmonltYszthO5NNA&export=download";
  const Upload = () => {
    handleUpload(file)
      .then((webViewLink) => {
        console.log('Uploaded file URL:', webViewLink);
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      });
  }

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const imageurl = "https://lh3.googleusercontent.com/d/1Cc8kr4_ZQYOd_BFICr865oS9wu4oN1i4"
  return (
    <div>
      <GoogleLoginComponent />

      <input type="file" accept="image/*, video/*" onChange={handleFileChange} />
      <button onClick={(file) => Upload(file)}>Upload Image/Video</button>
      <img
        src={imageurl}
        alt="Post Property and Earn from it Nyapata"
        className="w-[300px] lg:w-[300px] mt-12 mx-8"
      />

    

    </div>
  );
};

export default UploadImageToDrive;
