import { useEffect, useState } from "react";
import { FiDelete } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Filters } from "../components/Home";
import {
  CTA,
  HeadeFilters,
  Pagination,
} from "../components/property";
import { PropertyList } from "../components/property";
import { closeFilterMenu, uiStore } from "../features/uiSlice";
import { request } from "../features/featchApi";
import { useParams } from "react-router-dom";
export const propertyTypes = [
  {
    id: "Ac",
    name: "AC(Air Conditioner)",
  },
  {
    id: "Internet",
    name: "WiFi/Internet",
  },
  {
    id: "Ro",
    name: "Filtered Water/RO",
  },
  {
    id: "WashingMachine",
    name: "Washing Machine",
  },
  {
    id: "Cleaningservices",
    name: "Cleaning Services",
  },
  {
    id: "Coed",
    name: "Coed",
  },
  {
    id: "Cctv",
    name: "CCTV",
  },
  {
    id: "Lift",
    name: "Lift",
  },
  {
    id: "Parking",
    name: "Parking",
  },
  {
    id: "Electricity",
    name: "Free Electricity",
  },
  {
    id: "Owner",
    name: "Only Rentees property",
  },

];


const PropertyFive = () => {
  const { isFilterMenuOpen } = useSelector(uiStore);

  const dispatch = useDispatch();
  const { PropertyType, lat, lng } = useParams();

  const handleCloseFiltermenu = (e) => {
    if (e.target.classList.contains("filter-modal"))
      dispatch(closeFilterMenu());
  };

  const [property, setProperties] = useState([]);

  const [selectedTypes, setSelectedTypes] = useState({});
  const [formData, setFormData] = useState({
    PgFlat: "",
    BHK: "",
    Furnishing: "",
    distance: 3000,
    lat:"",
    lng:"",
  });


  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(()=>{
    RecivePropertiesBack();
  },[formData,selectedTypes])

  



  const handleCheckboxChange = (id) => {
    setSelectedTypes((prevSelectedTypes) => ({
      ...prevSelectedTypes,
      [id]: !prevSelectedTypes[id],
    }));

  };

  const RecivePropertiesBack = async () => {
    try {

      let link = "/propertiesf?BHK="+ formData.BHK +"&Furnishing="+formData.Furnishing+"&radius="+formData.distance+"&PropertyType="+PropertyType+"&lat="+lat+"&lng="+lng ;
      Object.keys(selectedTypes).forEach((key) => {
        link += `&${key}=${selectedTypes[key] ? 1 : 0}`;
      });
      const data = await request(link, 'GET')
      setProperties(data)

    } catch (error) { 
      console.error(error.message)
    }
  }

  return (
    <div className="pt-20 px-[3%] md:px-[6%]">
      <div className="my-20">
        <Filters />
      </div>

      <HeadeFilters />

      <div className="grid md:grid-cols-4 gap-x-14 mt-5">
        {property.length === 0 ?<h1 className="md:col-span-3 text-lg mt-5 md:mt-0 h-fit md:sticky top-0 ">No property available</h1>:
        <>
        <div className="md:col-span-3 md:mx-5 md:mt-0 h-fit md:sticky top-0 ">
          <PropertyList />
          <Pagination itemsPerPage={12} pageData={property} />
        </div>
        </>}
        
        <div className=" md:col-span-1 row-start-3 md:row-start-auto h-fit md:sticky top-0">
          <div
            className={`filter-modal ${isFilterMenuOpen && "open"}`}
            onClick={handleCloseFiltermenu}
          >
            <div className={`filter-dialog ${isFilterMenuOpen && "open"}`}>
              <div className="flex-center-between border-b dark:border-dark md:hidden">
                <div
                  className="icon-box md:hidden"
                  onClick={() => dispatch(closeFilterMenu())}
                >
                  <FiDelete />
                </div>
                <p className="uppercase">Filters</p>
              </div>

              {/* Fileters */}

              <div className="p-3 border dark:border-dark">
                <h1 className="font-semibold">Advanced Search</h1>

                <div className="mt-3">
                  <select
                    name="Furnishing"
                    value={formData.Furnishing}
                    onChange={handleInputChange}
                    className="filter"
                  >
                    <option value="">Furnishing</option>
                    <option value="f">Furnishied</option>
                    <option value="s">Semi-Furnishied</option>
                    <option value="u">UnFurnished</option>
                  </select>
                </div>

                <div className="gap-2 mt-3 flex-align-center">
                  <select
                    name="BHK"
                    value={formData.BHK}
                    onChange={handleInputChange}
                    className="filter"
                  >
                    <option value="">BHK</option>
                    <option value="1">1 BHK</option>
                    <option value="2">2 BHK</option>
                    <option value="3">3 BHK</option>
                    <option value="4">4 BHK</option>
                  </select>
                </div>

                <div className="my-3">
                  <label className="block text-sm font-semibold">
                    Distance: {formData.distance} meters
                  </label>
                  <input
                    type="range"
                    name="distance"
                    value={formData.distance}
                    onChange={handleInputChange}
                    min="0"
                    max="5000"
                    step="200"
                    className="mt-1 focus:outline-none block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                {propertyTypes.map(({ id, name, number }) => (
                  <div key={id} className="mt-3 filter flex-center-between">
                    <div className="input-cHECKBOX">
                      <input
                        type="checkbox"
                        name="type"
                        id={name}
                        onChange={() => handleCheckboxChange(id)}
                        checked={selectedTypes[id]}
                      />
                      <label htmlFor={name} className="capitalize">
                        &nbsp; {name}
                      </label>
                    </div>
                  </div>
                ))}


              </div>

              <CTA />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyFive;
